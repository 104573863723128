import styled from "styled-components";

export const StyledFilterTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 4px 8px;
  font-size: 0.75rem;
  border: 1px solid ${props => props.theme.primary.main};
  color: ${props => props.theme.primary.main};
  border-radius: 4px;
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  svg:hover {
    color: ${props => props.theme.error.main};
  }
`