import {useEffect, useState} from "react";

export function useColumnExchange(tableName: string, columns: string[]) {
    const LOCAL_STORAGE_KEY = `${tableName}_tableColumnsOrder`;
    const storedColumnsOrder = localStorage.getItem(LOCAL_STORAGE_KEY);
    const [columnsOrder, setColumnsOrder] = useState<string[]>(storedColumnsOrder ? JSON.parse(storedColumnsOrder) : columns);

    const exchangeColumns = (a: string, b: string) => {
        const newOrder = [...columnsOrder];
        const indexA = newOrder.indexOf(a);
        const indexB = newOrder.indexOf(b);
        const tmp = newOrder[indexA];
        newOrder[indexA] = newOrder[indexB];
        newOrder[indexB] = tmp;
        setColumnsOrder(newOrder);
    }

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(columnsOrder));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsOrder]);

    return {
        columnsOrder,
        exchangeColumns
    }
}