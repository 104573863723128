import {ColumnFilter, TableColumn, ValueType} from "../../domain/columns.model";
import {
    StyledFiltersContainer,
    StyledFiltersHeader
} from "../../styled-components/StyledFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import {AddFilter} from "./AddFilter";
import {useState} from "react";

interface TableFilterProps<T> {
    columns: TableColumn<T>[];
    filters?: ColumnFilter<ValueType>[];
    addFilter: (filter: ColumnFilter<ValueType>) => void;
}

function TableFilter<T>({columns, filters, addFilter}: TableFilterProps<T>){

    const [ open, setOpen ] = useState<boolean>(false);

    const switchAddFilter = () => {
        setOpen(!open);
    }

    const applyFilter = (data: ColumnFilter<ValueType>) => {
        addFilter(data);
        switchAddFilter();
    }

    return (
        <StyledFiltersContainer>
            <StyledFiltersHeader onClick={switchAddFilter}><FilterListIcon /></StyledFiltersHeader>
            <AddFilter<T> show={open} columns={columns} filters={filters} onApply={applyFilter} onCancel={switchAddFilter} />
        </StyledFiltersContainer>
    )
}

export default TableFilter;