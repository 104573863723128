import styled from "styled-components";

interface TopbarContentProps {
    disableTitle?: boolean;
}

export const TopbarContent = styled.div<TopbarContentProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px 0 0 0;
  overflow-y: auto;
  /* background: url("/beach-background-sea.webp");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; */
  @media (min-width: 1024px) {
    padding: 0;
    height: ${props => props.disableTitle ? '100vh' : 'calc(100vh - 50px)'};
    display: grid;
    grid-template-rows: ${props => props.disableTitle ? 'inherit' : '50px calc(100% - 50px)'} ;
  }
`;
