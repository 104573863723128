import { useContext } from "react";
import { SidebarContext } from "../../../Sidebar/SidebarContext";
import { StyledBlur } from '../styled-components/StyledBlur';

const Blur = () => {
    const { sidebarToggle } = useContext(SidebarContext);
    return (
      sidebarToggle?<StyledBlur data-testid="Blur"/>:null
    );
};
export default Blur;
