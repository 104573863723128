import { Amenity, EndpointAmenity } from "../domain/Amenity.model";

export const createAdapterAmenity = (amenity: EndpointAmenity) => {
  const formattedAmenity: Amenity = {
    id: amenity.id,
    name: amenity.name,
    icon: amenity.icon,
    categories: amenity.categories,
  };
  return formattedAmenity;
};
