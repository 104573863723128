import {TableColumn} from "../../domain/columns.model";
import {useState, useRef, useEffect} from "react";
import {
    StyledColumnsSelectorContainer,
    StyledColumnsSelectorHeader
} from "../../styled-components/StyledColumnSelector";
import SettingsIcon from '@mui/icons-material/Settings';
import {ColumnSelectable} from "../../tableUtils/columnSelector.hook";
import {SelectColumnList} from "./SelectColumnList";

interface TableColumnSelectorProps<T> {
    tableName: string;
    columns: TableColumn<T>[];
    initialColumns: ColumnSelectable[];
    selectedColumns: ColumnSelectable[];
    addColumn: (column: ColumnSelectable) => void;
    removeColumn: (column: ColumnSelectable) => void;
}

export function TableColumnSelector<T>({initialColumns, selectedColumns, addColumn, removeColumn}: TableColumnSelectorProps<T>){

    const [ open, setOpen ] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const switchAddFilter = () => {
        setOpen(!open);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [open]);


    return (
        <StyledColumnsSelectorContainer ref={containerRef}>
            <StyledColumnsSelectorHeader onClick={switchAddFilter}><SettingsIcon /></StyledColumnsSelectorHeader>
            <SelectColumnList
                show={open}
                columns={initialColumns}
                selectedColumns={selectedColumns}
                addColumn={addColumn}
                removeColumn={removeColumn}  />
        </StyledColumnsSelectorContainer>
    )
}