import {EndpointUser, NOT_RESORT_ID, User} from "../domain/User.model";
import {createAdapterRole} from "../../role/adapters/role.adapter";

export const createAdapterUser = (user: EndpointUser) => {
    const formattedUser: User = {
        id: user?.id,
        username: user.username,
        email: user.email,
        role: user.role ? createAdapterRole(user.role) : undefined,
        resort: user.metadata?.resort,
        firstname: user.metadata?.firstname,
        lastname: user.metadata?.lastname 
        
    };
    return formattedUser;
}

export const createAdapterEndpointUser = (user: User) => {
    const formattedEndpointUser: EndpointUser = {
        id: user?.id,
        username: user.username,
        password: user.password,
        email: user.email,
        role: user.role ? user.role : undefined,
        metadata:  
        { 
            resort: user.resort !== NOT_RESORT_ID ? user.resort : undefined,
            firstname: !!user.firstname || !!user.lastname ? user.firstname : undefined, 
            lastname: !!user.firstname || !!user.lastname? user.lastname : undefined
        } 
    };
    return formattedEndpointUser;
}