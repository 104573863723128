import styled from "styled-components";

export const StyledBreadcrumb = styled.span`
  display: none;
  flex-direction: row;
  list-style: none;
  @media screen and (min-width: 600px){
   display: flex}
`;

export const BreadcrumbsPag = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  background-color: ${props => props.theme.layout.breadcrum.background};
  padding: 0.5em;
  margin-right: 10px;
  transform: skewX(-15deg);

  a {
    text-decoration: none;
    color: ${props => props.theme.layout.breadcrum.textColor};
    font-size: 14px;
    transform: skewX(15deg);
    font-weight: 600;
    :first-letter {
    text-transform: uppercase;
    }   
  }
`;

export const BreadcrumbsPagInicio = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  background-color: ${props => props.theme.layout.breadcrum.background};
  padding: 0.8em;
  margin-right: 6px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, calc(100% - 0px) 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, calc(95% - 4px) 100%, 0% 100%);

  a {
    text-decoration: none;
    color: ${props => props.theme.layout.breadcrum.textColor};
    font-size: 14px;
    margin-right: 0.2em;
    font-weight: 600;
    :first-letter {
    text-transform: uppercase;
    }
  }
`;

export const BreadcrumbsPagActive = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  background-color: ${props => props.theme.layout.breadcrum.backgroundActive};
  padding: 0.5em;
  margin-right: 10px;
  transform: skewX(-15deg);

  a {
    text-decoration: none;
    color: ${props => props.theme.layout.breadcrum.textColorActive};
    font-size: 14px;
    transform: skewX(15deg);
    font-weight: 600;
    :first-letter {
    text-transform: uppercase;
    }
  }
`;

export const BreadcrumbsPagInicioActive = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  background-color: ${props => props.theme.layout.breadcrum.backgroundActive};
  padding: 0.8em;
  margin-right: 6px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, calc(100% - 0px) 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, calc(95% - 4px) 100%, 0% 100%);

  a {
    text-decoration: none;
    color: ${props => props.theme.layout.breadcrum.textColorActive};
    font-size: 14px;
    transform: skewX(0deg);
    font-weight: 600;
    margin-right: 0.2em; 
    :first-letter {
    text-transform: uppercase;
    }
  }
`;
