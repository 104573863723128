import {
    StyledAddColumnsSelectorContainer,
    StyledFormTableColumnsSelector, StyledFormTableColumnsSelectorHeader
} from "../../styled-components/StyledColumnSelector";
import {useForm} from "react-hook-form";
import {InputType} from "../../../Inputs/Input";
import {Checkbox} from "../../../Inputs/Checkbox";
import {ColumnSelectable} from "../../tableUtils/columnSelector.hook";
import translate from "../../../../../language/translate";

interface SelectColumnListProps {
    show: boolean;
    columns: ColumnSelectable[];
    selectedColumns: ColumnSelectable[];
    addColumn: (column: ColumnSelectable) => void;
    removeColumn: (column: ColumnSelectable) => void;
}

export function SelectColumnList({ show, columns, selectedColumns,  addColumn, removeColumn }: SelectColumnListProps) {

    const {
        register
    } = useForm<any>({
        defaultValues: columns.reduce((result: { [key: string]: boolean }, item) => {
            result[item.field] = !!selectedColumns.find(col => col.field === item.field);
            return result;
        }, {}),
        mode: 'all'
    });

    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;
        const column = columns.find(col => col.field === name);
        if(column){
            if(checked){
                addColumn(column);
            } else {
                removeColumn(column);
            }
        }
    };
    return (
        <StyledAddColumnsSelectorContainer show={show}>
            <StyledFormTableColumnsSelector>
                <StyledFormTableColumnsSelectorHeader>{translate('selected columns')}</StyledFormTableColumnsSelectorHeader>
                {
                    columns.map((column, index) => (
                        <Checkbox
                            key={`column_option_${index}`}
                            register={register(column.field)}
                            id={column.field}
                            name={column.field}
                            label={column.label}
                            type={InputType.CHECKBOX}
                            onChange={handleCheckboxChange}
                        />
                    ))
                }
            </StyledFormTableColumnsSelector>
        </StyledAddColumnsSelectorContainer>
    )
}