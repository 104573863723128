import styled from "styled-components";

export const StyledApplyFilterIcon = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.primary.main};
  transition: all 150ms ease-in-out;
  svg {
    width: 25px;
    height: 25px;
    color: ${props => props.theme.primary.main};
    transition: all 150ms ease-in-out;
  }
  &:hover {
    background: ${props => props.theme.primary.main};;
    svg {
      transform: scale(1.1);
      color: ${props => props.theme.base.main};
    }
  }
`

export const StyledCancelFilterIcon = styled(StyledApplyFilterIcon)`
  right: 50px;
`