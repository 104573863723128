import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../config";

const API_URL = appConfig.API_URL_WEATHER;
const API_ID = appConfig.API_WEATHER;

const WEATHER_TAG = "Weather";

export const weatherApi = createApi({
    reducerPath: "WeatherApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL
    }),
    tagTypes: [WEATHER_TAG],
    endpoints: (builder) => ({
        getWeatherByCity: builder.query<any, string>({
            query: (city) => `?q=${city}&appid=${API_ID}&lang=es&units=metric`,
            providesTags: [WEATHER_TAG]
        })
    }),
});

export const { useGetWeatherByCityQuery } = weatherApi;
