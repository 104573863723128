import {City, EndpointCity} from "../domain/City.model";

export const createAdapterCity = (city: EndpointCity) => {
    const formattedCity: City = {
        id: city.id,
        slug: city.slug,
        name: city.name,
        description: city.description
    };
    return formattedCity;
}