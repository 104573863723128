import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../config";
import {TableOptions} from "../../../shared-kernel/components/Table";
import {createAdapterCharacteristic} from "../adapters/characteristic.adapter";
import {Characteristic, CharacteristicDataProps, EndpointCharacteristic} from "../domain/Characteristic.model";
import {ResortApplication} from "../../../resort/domain/Resort.model";


const API_URL = appConfig.API_URL;

const CHARACTERISTICS_TAG = "Characteristics";

export const characteristicApi = createApi({
    reducerPath: "CharacteristicApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [CHARACTERISTICS_TAG],
    endpoints: (builder) => ({
        getCharacteristics: builder.query<CharacteristicDataProps, TableOptions<Characteristic> & ResortApplication | undefined>({
            query: (tableOptions) =>{
                const applicationId = tableOptions?.applicationId ?? appConfig.APPLICATION_ID;
                let queryParams: string = `applicationId=${applicationId}`;
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `&page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `characteristics${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [CHARACTERISTICS_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.map((characteristic: EndpointCharacteristic) => createAdapterCharacteristic(characteristic)),
                    page: 1,
                    total: response.length,
                };
            }
        }),
        updateCharacteristic: builder.mutation<any, any>({
            query: (data: any) => {
                const bodyData = createAdapterCharacteristic(data);
                return {
                    url: `characteristics/${data.id}`,
                    method: "PUT",
                    body: {
                        ...bodyData,
                        applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [CHARACTERISTICS_TAG],
        }),
        getCharacteristicById: builder.query<EndpointCharacteristic, string | undefined>({
            query: (id) => `characteristics/${id}`,
            providesTags: (result, error, id) => [{ type: 'Characteristics', id: id }],
            transformResponse: (response: EndpointCharacteristic) => createAdapterCharacteristic(response),
        }),
        createCharacteristic: builder.mutation<any, any>({
            query: (data) => ({
                url: 'characteristics/',
                method: "POST",
                body: {
                    ...data,
                    applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [CHARACTERISTICS_TAG],
        }),
        deleteCharacteristic: builder.mutation<any, any>({
            query: (id) => ({
                url: `characteristics/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [CHARACTERISTICS_TAG]
        }),
    }),
});

export const { useGetCharacteristicByIdQuery, useGetCharacteristicsQuery, useCreateCharacteristicMutation, useUpdateCharacteristicMutation, useDeleteCharacteristicMutation } = characteristicApi;
