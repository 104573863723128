import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {TableOptions} from "../../shared-kernel/components/Table";
import {appConfig} from "../../config";
import {EndpointPromotion, Promotion, PromotionDataProps} from "../domain/Promotion.model";
import {createAdapterPromotion} from "../adapters/promotion.adapter";
import {ResortApplication} from "../../resort/domain/Resort.model";

const API_URL = appConfig.API_URL;

const PROMOTIONS_TAG = "Promotions";

export const promotionApi = createApi({
    reducerPath: "PromotionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [PROMOTIONS_TAG],
    endpoints: (builder) => ({
        getPromotions: builder.query<PromotionDataProps, TableOptions<Promotion> & ResortApplication | undefined>({
            query: (tableOptions) =>{
                const applicationId = tableOptions?.applicationId ?? appConfig.APPLICATION_ID;
                let queryParams: string = `applicationId=${applicationId}`;
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `&page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `promotions/list${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [PROMOTIONS_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.data.map((promotion: EndpointPromotion) => createAdapterPromotion(promotion)),
                    page: response.page,
                    total: response.total,
                };
            }
        }),
        updatePromotion: builder.mutation<any, EndpointPromotion & ResortApplication>({
            query: (data) => {
                const bodyData = createAdapterPromotion(data);
                return {
                    url: `promotions/${data.id}`,
                    method: "PUT",
                    body: {
                        ...bodyData,
                        applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [PROMOTIONS_TAG],
        }),
        getPromotionById: builder.query<EndpointPromotion, string | undefined>({
            query: (id) => `promotions/${id}?applicationId=${appConfig.APPLICATION_ID}`,
            providesTags: (result, error, id) => [{ type: 'Promotions', id: id }],
            transformResponse: (response: EndpointPromotion) => createAdapterPromotion(response),
        }),
        createPromotion: builder.mutation<any, any>({
            query: (data) => ({
                url: 'promotions/',
                method: "POST",
                body: {
                    ...data,
                    applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [PROMOTIONS_TAG],
        }),
        deletePromotion: builder.mutation<any, any>({
            query: (id) => ({
                url: `promotions/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [PROMOTIONS_TAG]
        }),
    }),
});

export const { useGetPromotionByIdQuery, useGetPromotionsQuery, useCreatePromotionMutation, useUpdatePromotionMutation, useDeletePromotionMutation,  } = promotionApi;
