import styled from "styled-components";

export const StyledTableHead = styled.thead`
  display: none;
  position: sticky;
  top: 0;
  width: 100%;
  white-space:nowrap;
  vertical-align: middle;
  height: 40px;
  z-index: 1;
  background: ${props => props.theme.table.bodyBackground};
  @media screen and (min-width: 600px) {
    display: table-header-group;
  }
`;