import {
  StyledProfileName,
  StyledProfileRole,
  StyledProfileText,
} from "../styled-components/StyledProfileText";
import { StyledProfilePicture } from "../styled-components/StyledProfilePicture";
import DropMenu from "./DropMenu";
import { useState } from "react";
import useSession from "../../../../../auth/services/useSession";
import {useGetResortByIdQuery} from "../../../../../resort/services/resortApi";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Profile = () => {

    const { userData } = useSession();

    const [openDropMenu, setOpenDropMenu] = useState(false);
    const resortId = userData.metadata?.resort;

    const {
        data: resort
    } = useGetResortByIdQuery(resortId, { skip: !resortId });
  const dropdownToggle = () => {
    setOpenDropMenu(!openDropMenu);
  };

  return (
    <>
      <StyledProfileText>
        <StyledProfileName>{userData.username}</StyledProfileName>
        <StyledProfileRole resort={resort}>{resort ? resort.name : null}</StyledProfileRole>
      </StyledProfileText>
      <StyledProfilePicture
        onClick={dropdownToggle}
      >
          <AccountCircleIcon/>
      </StyledProfilePicture>
      <DropMenu openDropMenu={openDropMenu} dropdownToggle={dropdownToggle} />
    </>
  );
};

export default Profile;
