import styled from "styled-components";

interface StyledMenuArrowProps {
    show: boolean;
}

export const StyledMenuArrow = styled.div<StyledMenuArrowProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  align-self: flex-end;
  svg {
    width: 15px;
    height: 15px;
  }
  @media (min-width: 1024px) {
    display: ${(props) => (props.show ? "flex" : "none")};
  }
`;