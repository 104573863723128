import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { userApi } from './user/services/userApi';
import { authApi } from './auth/services/authApi';
import { authSlice } from './auth/presentation/state/auth.reducer';
import { themeSlice } from './theme/presentation/state/theme.reducer';
import { languageSlice } from './language/presentation/state/language.reducer';
import {menuApi} from "./menu/services/menuApi";
import {notificationSlice} from "./shared-kernel/components/NotificationBar/state/notification.reducer";
import { timezoneSlice } from './timezone/state/timezone.reducer';
import { permissionApi } from './permission/services/permissionApi';
import { roleApi } from './role/services/roleApi';
import {resortApi} from "./resort/services/resortApi";
import {cityApi} from "./city/services/cityApi";
import {amenityApi} from "./amenity/services/amenityApi";
import {promotionApi} from "./promotion/services/promotionApi";
import {brandApi} from "./catalogue/brand/services/brandApi";
import {categoryApi} from "./catalogue/category/services/categoryApi";
import {characteristicApi} from "./catalogue/characteristic/services/characteristicApi";
import {productApi} from "./catalogue/product/services/productApi";
import {inventoryApi} from "./catalogue/inventory/services/inventoryApi";
import {priceScheduleApi} from "./catalogue/price-schedule/services/PriceScheduleApi";
import { productInventoryApi } from './catalogue/productInventory/services/productInventoryApi';
import { bookingApi } from './booking/services/bookingApi';
import {weatherApi} from "./booking/services/weatherApi";
import {resumeApi} from "./shared-kernel/components/Resume/services/resumeApi";

export const store = configureStore({
  reducer: {
    [menuApi.reducerPath]: menuApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [resortApi.reducerPath]: resortApi.reducer,
    [cityApi.reducerPath]: cityApi.reducer,
    [promotionApi.reducerPath]: promotionApi.reducer,
    [amenityApi.reducerPath]: amenityApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [characteristicApi.reducerPath]: characteristicApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [priceScheduleApi.reducerPath]: priceScheduleApi.reducer,
    [productInventoryApi.reducerPath]: productInventoryApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [weatherApi.reducerPath]: weatherApi.reducer,
    [resumeApi.reducerPath]: resumeApi.reducer,
    session: authSlice.reducer,
    theme: themeSlice.reducer,
    timezone: timezoneSlice.reducer,
    language: languageSlice.reducer,
    notification: notificationSlice.reducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([menuApi.middleware, userApi.middleware, permissionApi.middleware, roleApi.middleware, authApi.middleware, resortApi.middleware, cityApi.middleware, amenityApi.middleware, promotionApi.middleware, brandApi.middleware, categoryApi.middleware, characteristicApi.middleware, productApi.middleware, inventoryApi.middleware, priceScheduleApi.middleware, productInventoryApi.middleware, bookingApi.middleware, weatherApi.middleware, resumeApi.middleware]),
});

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch