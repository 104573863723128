import {SidebarMenuEndpoint, SidebarMenu} from "../domain/sidebarNav";


function getSubmenus(menuEndpoint: SidebarMenuEndpoint, menuList: SidebarMenuEndpoint[]){
    const submenus: SidebarMenu[] = [];
    menuList.forEach(menu => {
        const submenuFather = typeof menu.parentMenu === 'string' ? menu.parentMenu : menu.parentMenu?.id;
        if(submenuFather === menuEndpoint.id){
            submenus.push({
                id: menu.id,
                name: menu.name,
                icon: menu.icon,
                action: menu.action,
                label: menu.alias,
                order: menu.order,
                submenus: getSubmenus(menu, menuList),
                parentMenu: !!menu.parentMenu
            });
        }
    })
    return submenus;
}

export const createAdapterToTopSidebarMenu = (menuItem: SidebarMenuEndpoint, menuItems?: SidebarMenuEndpoint[]) => {
    const formattedMenu: SidebarMenu = {
        id: menuItem.id,
        name: menuItem.name,
        icon: menuItem.icon,
        action: menuItem.action,
        label: menuItem.alias,
        order: menuItem.order,
        submenus: menuItems ? getSubmenus(menuItem, menuItems) : [],
        parentMenu: !!menuItem.parentMenu
    };
    return formattedMenu;
}