import {EndpointPromotion, Promotion} from "../domain/Promotion.model";

export const createAdapterPromotion = (promotion: EndpointPromotion) => {
    const formattedPromotion: Promotion = {
        id: promotion.id,
        applicationId: promotion.applicationId,
        name: promotion.name,
        description: promotion.description,
        validityFrom: promotion.validityFrom,
        validityTo: promotion.validityTo,
        parameters: promotion.parameters,
        priceModifier: promotion.priceModifier,
        url: promotion.url,
        enabled: promotion.enabled,
    };
    return formattedPromotion;
}