import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { BaseLayout, TopbarLayout } from "./shared-kernel/components/Layouts";
import Protected from "./auth/presentation/components/Protected";
import MenusTable from "./menu/component/MenusTable";
import SuspenseLoader from "./shared-kernel/components/SuspenseLoader/SuspenseLoader";
import SecureSlug from "./auth/presentation/components/SecureSlug";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(
  lazy(() => import("./auth/presentation/components/Login"))
);
const AccessButtons = Loader(lazy(() => import("./shared-kernel/components/Layouts/MenuAccessLayout/components/AccessButtons")));
const Home = Loader(lazy(() => import("./shared-kernel/components/Home/Home")));
const ReservationMap = Loader(lazy(() => import("./booking/presentation/ReservationMap")));
const Resume = Loader(lazy(() => import("./shared-kernel/components/Resume/Resume")));
const NonResourseMenu = Loader(
  lazy(
    () => import("./shared-kernel/components/NonRecourseMenu/NonResourseMenu")
  )
);
const Menus = Loader(lazy(() => import("./menu/presentation/Menus")));
const NewMenu = Loader(lazy(() => import("./menu/component/NewMenu")));
const EditMenu = Loader(lazy(() => import("./menu/component/EditMenu")));

const Users = Loader(lazy(() => import('./user/presentation/Users')));
const UserEdit = Loader(lazy(() => import('./user/components/UserEdit')));
const UsersTable = Loader(lazy(() => import('./user/components/UsersTable')));
const Permissions = Loader(lazy(() => import('./permission/presentation/Permissions')));
const PermissionEdit = Loader(lazy(() => import('./permission/components/PermissionEdit')));
const PermissionsTable = Loader(lazy(() => import('./permission/components/PermissionsTable')));
const Roles = Loader(lazy(() => import('./role/presentation/Roles')));
const RoleEdit = Loader(lazy(() => import('./role/components/RoleEdit')));
const RolesTable = Loader(lazy(() => import('./role/components/RolesTable')));

const Resorts = Loader(lazy(() => import('./resort/presentation/Resorts')));
const ResortEdit = Loader(lazy(() => import('./resort/components/ResortEdit')));
const ResortsTable = Loader(lazy(() => import('./resort/components/ResortsTable')));

const Cities = Loader(lazy(() => import('./city/presentation/Cities')));
const CityEdit = Loader(lazy(() => import('./city/components/CityEdit')));
const CitiesTable = Loader(lazy(() => import('./city/components/CitiesTable')));

const Promotions = Loader(lazy(() => import('./promotion/presentation/Promotions')));
const PromotionEdit = Loader(lazy(() => import('./promotion/components/PromotionEdit')));
const PromotionsTable = Loader(lazy(() => import('./promotion/components/PromotionsTable')));

const Amenities = Loader(lazy(() => import('./amenity/presentation/Amenities')));
const AmenityEdit = Loader(lazy(() => import('./amenity/components/AmenityEdit')));
const AmenitiesTable = Loader(lazy(() => import('./amenity/components/AmenitiesTable')));

const UserProfile = Loader(
  lazy(() => import("./user/presentation/UserProfile"))
);

const Settings = Loader(
  lazy(() => import("./shared-kernel/components/Settings/Settings"))
);

const Products = Loader(
  lazy(() => import("./catalogue/product/presentation/Products"))
);
const EditProduct = Loader(
  lazy(() => import("./catalogue/product/components/ProductEdit"))
);
const ProductsTable = Loader(
    lazy(() => import("./catalogue/product/components/ProductsTable"))
);


const ProductsInventory = Loader(
  lazy(() => import("./catalogue/productInventory/presentation/ProductsInventory"))
);
const EditProductInventory = Loader(
  lazy(() => import("./catalogue/productInventory/components/ProductInventoryEdit"))
);
const ProductsInventoryTable = Loader(
    lazy(() => import("./catalogue/productInventory/components/ProductsInventoryTable"))
);

const Bookings = Loader(
  lazy(() => import("./booking/presentation/Booking"))
);
const EditBookings = Loader(
  lazy(() => import("./booking/components/BookingEdit"))
);
const BookingsTable = Loader(
    lazy(() => import("./booking/components/BookingsTable"))
);

const PriceSchedules = Loader(
    lazy(() => import("./catalogue/price-schedule/presentation/PriceSchedule"))
);
const EditPriceSchedule = Loader(
    lazy(() => import("./catalogue/price-schedule/components/PriceScheduleEdit"))
);
const PriceSchedulesTable = Loader(
    lazy(() => import("./catalogue/price-schedule/components/PriceSchedulesTable"))
);

const Tags = Loader(lazy(() => import("./catalogue/tags/presentation/Tags")));
const NewTag = Loader(lazy(() => import("./catalogue/tags/components/NewTag")));
const EditTag = Loader(
  lazy(() => import("./catalogue/tags/components/EditTag"))
);

const Brands = Loader(
  lazy(() => import("./catalogue/brand/presentation/Brands"))
);
const EditBrand = Loader(
  lazy(() => import("./catalogue/brand/components/BrandEdit"))
);
const BrandsTable = Loader(lazy(() => import('./catalogue/brand/components/BrandsTable')));

const Categories = Loader(
  lazy(() => import("./catalogue/category/presentation/Categories"))
);
const EditCategory = Loader(
  lazy(() => import("./catalogue/category/components/CategoryEdit"))
);
const CategoriesTable = Loader(lazy(() => import('./catalogue/category/components/CategoriesTable')));

const Inventories = Loader(
  lazy(() => import("./catalogue/inventory/presentation/Inventory"))
);
const InventoryEdit = Loader(
  lazy(() => import("./catalogue/inventory/components/InventoryEdit"))
);

const InventoriesTable = Loader(lazy(() => import('./catalogue/inventory/components/InventoriesTable')));

const Characteristics = Loader(
    lazy(() => import("./catalogue/characteristic/presentation/Characteristics"))
);
const EditCharacteristic = Loader(
    lazy(() => import("./catalogue/characteristic/components/EditCharacteristic"))
);
const CharacteristicsTable = Loader(lazy(() => import('./catalogue/characteristic/components/CharacteristicsTable')));

const Maps = Loader(lazy(() => import('./maps/presentation/Maps')));
const MapsTable = Loader(lazy(() => import('./maps/components/MapsTable')));
const MapEditor = Loader(lazy(() => import('./maps/components/MapEditor')));


// SEAT MAP

/*const SeatMapReservation = Loader(lazy(() => import("./seatmap-reservation/presentation/SeatMapReservation")));*/

// Status
const NotFound = Loader(
  lazy(() => import("./shared-kernel/components/Status/NotFound/NotFound"))
);
const Status500 = Loader(
  lazy(
    () =>
      import(
        "./shared-kernel/components/Status/InternalServerError/InternalServerError"
      )
  )
);
const StatusComingSoon = Loader(
  lazy(() => import("./shared-kernel/components/Status/ComingSoon/CominSoon"))
);
const StatusMaintenance = Loader(
  lazy(
    () => import("./shared-kernel/components/Status/Maintenance/Maintenance")
  )
);

const routes: RouteObject[] = [
  {
    path: "/login",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Protected>
        <TopbarLayout />
      </Protected>
    ),
    children: [
      {
        path: "",
        // element: <Navigate to="home" replace />,
        element: <AccessButtons />,
      },
      {
        path: "home",
        element: <Home />,
        children: [
          {
            path: "",
            element: <ReservationMap />,
          }
        ]
      },
      {
        path: "resume",
        element: <Resume />,
      },
      {
        path: "/profile",
        element: (
          <Protected>
            <UserProfile />
          </Protected>
        ),
      },
      {
        path: "/settings",
        element: (
          <Protected>
            <Settings />
          </Protected>
        ),
      },
      {
        path: "/zones",
        element: <SecureSlug><Inventories /></SecureSlug>,
        children: [
          {
            path: "",
            element: <InventoriesTable />,
          },
          {
            path: "add",
            element: <InventoryEdit />,
          },
          {
            path: "edit/:inventoryId",
            element: <InventoryEdit />,
          },
        ],
      },
      {
        path: "/products",
        element: <SecureSlug><Products /></SecureSlug>,
        children: [
          {
            path: "",
            element: <ProductsTable />,
          },
          {
            path: "add",
            element: <EditProduct />,
          },
          {
            path: "edit/:productId",
            element: <EditProduct />,
          },
        ],
      },
      {
        path: "/instanceszone",
        element: <SecureSlug><ProductsInventory /></SecureSlug>,
        children: [
          {
            path: "",
            element: <ProductsInventoryTable />,
          },
          {
            path: "add",
            element: <EditProductInventory />,
          },
          {
            path: "edit/:productInventoryId",
            element: <EditProductInventory />,
          },
        ],
      },
      {
        path: "/maps",
        element: <SecureSlug><Maps /></SecureSlug>,
        children: [
          {
            path: "",
            element: <MapsTable />,
          },
          {
            path: "editor",
            element: <MapEditor />,
          },
          {
            path: "edit/:mapId",
            element: <MapEditor />,
          },
        ],
      },
      {
        path: "/priceschedules",
        element: <SecureSlug><PriceSchedules /></SecureSlug>,
        children: [
          {
            path: "",
            element: <PriceSchedulesTable />,
          },
          {
            path: "add",
            element: <EditPriceSchedule />,
          },
          {
            path: "edit/:priceScheduleId",
            element: <EditPriceSchedule />,
          },
        ],
      },
      {
        path: "/bookings",
        element: <SecureSlug><Bookings /></SecureSlug>,
        children: [
          {
            path: "",
            element: <BookingsTable />,
          },
          {
            path: "add",
            element: <EditBookings />,
          },
          {
            path: "edit/:bookingId",
            element: <EditBookings />,
          },
        ],
      },
      {
        path: "/tags",
        element: <SecureSlug><Tags /></SecureSlug>,
        children: [
          {
            path: "add",
            element: <NewTag />,
          },
          {
            path: "edit/:id",
            element: <EditTag />,
          },
        ],
      },
      {
        path: "/brands",
        element: <SecureSlug><Brands /></SecureSlug>,
        children: [
          {
            path: "",
            element: <BrandsTable />,
          },
          {
            path: "add",
            element: <EditBrand />,
          },
          {
            path: "edit/:brandId",
            element: <EditBrand />,
          }
        ],
      },
      {
        path: "/categories",
        element: <SecureSlug><Categories /></SecureSlug>,
        children: [
          {
            path: "",
            element: <CategoriesTable />,
          },
          {
            path: "add",
            element: <EditCategory />,
          },
          {
            path: "edit/:categoryId",
            element: <EditCategory />,
          },
        ],
      },
      {
        path: "/characteristics",
        element: <SecureSlug><Characteristics /></SecureSlug>,
        children: [
          {
            path: "",
            element: <CharacteristicsTable />,
          },
          {
            path: "add",
            element: <EditCharacteristic />,
          },
          {
            path: "edit/:characteristicId",
            element: <EditCharacteristic />,
          },
        ],
      },
      {
        path: "menus",
        element: <SecureSlug><Menus /></SecureSlug>,
        children: [
          {
            path: "",
            element: <MenusTable />,
          },
          {
            path: "add",
            element: <NewMenu />,
          },
          {
            path: "edit/:id",
            element: <EditMenu />,
          },
        ],
      },
      {
        path: "users",
        element: <SecureSlug><Users /></SecureSlug>,
        children: [
          {
            path: '',
            element: <UsersTable />
          },
          {
            path: "add",
            element: <UserEdit />,
          },
          {
            path: "edit/:userId",
            element: <UserEdit />,
          },
        ]
      },
      {
        path: "permissions",
        element: <SecureSlug><Permissions /></SecureSlug>,
        children: [
          {
            path: '',
            element: <PermissionsTable />
          },
          {
            path: "add",
            element: <PermissionEdit />,
          },
          {
            path: "edit/:permissionId",
            element: <PermissionEdit />,
          },
        ]
      },
      {
        path: "roles",
        element: <SecureSlug><Roles /></SecureSlug>,
        children: [
          {
            path: '',
            element: <RolesTable />
          },
          {
            path: "add",
            element: <RoleEdit />,
          },
          {
            path: "edit/:roleId",
            element: <RoleEdit />,
          },
        ]
      },
      {
        path: "resorts",
        element: <SecureSlug><Resorts /></SecureSlug>,
        children: [
          {
            path: '',
            element: <ResortsTable />
          },
          {
            path: "add",
            element: <ResortEdit />,
          },
          {
            path: "edit/:resortId",
            element: <ResortEdit />,
          },
        ]
      },
      {
        path: "cities",
        element: <SecureSlug><Cities /></SecureSlug>,
        children: [
          {
            path: '',
            element: <CitiesTable />
          },
          {
            path: "add",
            element: <CityEdit />,
          },
          {
            path: "edit/:cityId",
            element: <CityEdit />,
          },
        ]
      },
      {
        path: "promotions",
        element: <SecureSlug><Promotions /></SecureSlug>,
        children: [
          {
            path: '',
            element: <PromotionsTable />
          },
          {
            path: "add",
            element: <PromotionEdit />,
          },
          {
            path: "edit/:promotionId",
            element: <PromotionEdit />,
          },
        ]
      },
      {
        path: "amenities",
        element: <SecureSlug><Amenities /></SecureSlug>,
        children: [
          {
            path: '',
            element: <AmenitiesTable />
          },
          {
            path: "add",
            element: <AmenityEdit />,
          },
          {
            path: "edit/:amenityId",
            element: <AmenityEdit />,
          },
        ]
      },
    ],
  },
  {
    path: "/status",
    children: [
      {
        path: "",
        element: <Navigate to="404" replace />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <Status500 />,
      },
      {
        path: "maintenance",
        element: <StatusMaintenance />,
      },
      {
        path: "coming-soon",
        element: <StatusComingSoon />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Protected>
        <TopbarLayout />
      </Protected>
    ),
    children: [
      {
        path: "*",
        element: <NonResourseMenu />,
      },
    ],
  },
];

export default routes;
