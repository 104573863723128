import {FC, ReactNode, useContext} from "react";
import {Outlet, useLocation} from "react-router-dom";
import { TopbarContent } from "./styled-components/TopbarContent";
import {StyledTopbarLayoutContainer} from "./styled-components/StyledTopbarLayoutContainer"
import TopbarNav from "./components/TopbarNav";
import Blur from "./components/Blur";
import {useGetSidebarMenusQuery} from "../../../../menu/services/menuApi";
import {useIntl} from "react-intl";
import {StyledPrincipalTitle} from "../../../../styled-components/StyledPrincipalTitle";
import {SidebarContext} from "../../Sidebar/SidebarContext";
import SidebarNav from "./components/SidebarNav";
import {StyledSidebarNavContainer} from "./styled-components/StyledSidebarNavContainer";
import useSession from "../../../../auth/services/useSession";
import {MENU_PERMISSION_BASE} from "../../../../menu/menu.constants";
import translate from "../../../../language/translate";
import TopbarTabs from "./components/TopbarTabs";

interface TopbarLayoutProps {
  children?: ReactNode;
}

const TopbarLayout: FC<TopbarLayoutProps> = ({ children }) => {
    const OPEN_NAV_WIDTH = "450px";
    const CLOSE_NAV_WIDTH = "0px";
    const { pathname } = useLocation();
    const intl = useIntl();
    const { hasPermission } = useSession();
    const { data, isSuccess } = useGetSidebarMenusQuery();
    const { sidebarToggle } = useContext(SidebarContext);
    const NO_TITLE_LAYOUT = ['/resume', '/home'];
    const BASE_PATH_NAME = '/';

    const menus = data?.filter(menu => hasPermission(`${MENU_PERMISSION_BASE}${menu.action}`))
  return (
    <StyledTopbarLayoutContainer>
          <TopbarNav/>
          <Blur/>
        <StyledSidebarNavContainer
            openNav={sidebarToggle}
            openWidth={OPEN_NAV_WIDTH}
            closeWidth={CLOSE_NAV_WIDTH}
            data-testid="SidebarNav"
        >
            <SidebarNav data={menus} isSuccess={isSuccess} openNav={sidebarToggle} />
        </StyledSidebarNavContainer>
        <TopbarTabs menus={menus} />
        <TopbarContent disableTitle={NO_TITLE_LAYOUT.includes(pathname)}>
            {
                pathname !== BASE_PATH_NAME && !NO_TITLE_LAYOUT.includes(pathname) ?
                    isSuccess ?
                        <StyledPrincipalTitle>{data?.find(menu => pathname.includes(menu?.action))?.name ?? translate(pathname?.split('/')[1])}</StyledPrincipalTitle>
                        :
                        <StyledPrincipalTitle>{intl.formatMessage({
                            id: "menu title",
                        })}</StyledPrincipalTitle>
                :
                    null
            }
            {children || <Outlet />}
        </TopbarContent>
    </StyledTopbarLayoutContainer>
  );
};

export default TopbarLayout;
