import styled from "styled-components";

interface SelectLayoutProps {
  hidden?: boolean;
  width?: string;
}

export const SelectLayout = styled.div<SelectLayoutProps>`
  position: relative;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  margin: 8px 0;
  width: ${props => props.width ? props.width : 'auto'};
`;

export const StyledSelect = styled.select`
  width: 100%;
  outline: none;
  font-size: 1em;
  color: ${(props) => props.theme.black.main};
  background: ${(props) => props.theme.inputs.select.background};
  padding: 8.5px 14px;
  overflow: auto;
  border: 1px solid ${(props) => props.theme.black.light};;
  border-radius: 4px;
  &:hover {
    border: 1px solid ${(props) => props.theme.black.light};
`;

export const StyledOption = styled.option`
  &:checked {
    background: ${(props) => props.theme.inputs.select.selectedBackground};
    color: ${(props) => props.theme.inputs.select.selectedColor};
  }
  &:not(:checked) {
    background: ${(props) => props.theme.inputs.select.background};
    color: ${(props) => props.theme.inputs.select.color};
  }
`;
