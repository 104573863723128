import styled from "styled-components";

export const StyledTable = styled.table`
    position: relative;
    width: 100%;
    overflow-x: auto;
    border-radius: 8px;
    border-collapse: separate;
`




