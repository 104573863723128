import styled from "styled-components";

export const StyledInputSearch = styled.input`
  width: 100%;
  font-size: 1rem;
  border: none;
  outline: none;
`;

interface StyledInputSearchContainerProps {
  haveFilters: boolean;
}

export const StyledInputSearchContainer = styled.div<StyledInputSearchContainerProps>`
  position: relative;
  align-self: flex-end;
  width: 150px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px 30px 10px 8px;
  background-color: ${props => props.theme.base.main};
  @media screen and (min-width: 600px) {
    width: 250px;
  }
`;

export const StyledCancelIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  svg:hover {
    color: rgba(0, 0, 0, 0.8);
  }
`;
