import {Brand, EndpointBrand} from "../domain/Brand.model";

export const createAdapterBrand = (brand: EndpointBrand) => {
    const formattedBrand: Brand = {
        id: brand.id,
        name: brand.name,
        logo: brand.logo,
        description: brand.description,
        order: brand.order
    };
    return formattedBrand;
}