import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../config";
import {TableOptions} from "../../../shared-kernel/components/Table";
import {ResortApplication} from "../../../resort/domain/Resort.model";
import {createAdapterPriceSchedule} from "../adapters/PriceSchedule.adapter";
import {EndpointPriceSchedule, PriceSchedule, PriceScheduleDataProps} from "../domain/PriceSchedule.model";

const API_URL = appConfig.API_URL;

const PRICE_SCHEDULES_TAG = "PriceSchedules";

export const priceScheduleApi = createApi({
    reducerPath: "PriceScheduleApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [PRICE_SCHEDULES_TAG],
    endpoints: (builder) => ({
        getPriceSchedules: builder.query<PriceScheduleDataProps, TableOptions<PriceSchedule> & ResortApplication | undefined>({
            query: (tableOptions) =>{
                const applicationId = tableOptions?.applicationId ?? appConfig.APPLICATION_ID;
                let queryParams: string = `applicationId=${applicationId}`;
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `&page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `priceSchedules/list${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [PRICE_SCHEDULES_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.data.map((inventory: EndpointPriceSchedule) => createAdapterPriceSchedule(inventory)),
                    page: response.page,
                    total: response.total,
                };
            }
        }),
        updatePriceSchedule: builder.mutation<any, any>({
            query: (data: any) => {
                return {
                    url: `priceSchedules/${data.id}`,
                    method: "PUT",
                    body: {
                        ...data,
                        applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [PRICE_SCHEDULES_TAG],
        }),
        getPriceScheduleById: builder.query<EndpointPriceSchedule, string | undefined>({
            query: (id) => `priceSchedules/${id}?applicationId=${appConfig.APPLICATION_ID}`,
            providesTags: (result, error, id) => [{ type: 'PriceSchedules', id: id }],
            transformResponse: (response: EndpointPriceSchedule) => createAdapterPriceSchedule(response),
        }),
        createPriceSchedule: builder.mutation<any, any>({
            query: (data: any) => ({
                url: 'priceSchedules/',
                method: "POST",
                body: {
                    ...data,
                    applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [PRICE_SCHEDULES_TAG],
        }),
        deletePriceSchedule: builder.mutation<any, any>({
            query: (id) => ({
                url: `priceSchedules/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [PRICE_SCHEDULES_TAG]
        }),
    }),
});

export const { useGetPriceScheduleByIdQuery, useGetPriceSchedulesQuery, useCreatePriceScheduleMutation, useUpdatePriceScheduleMutation, useDeletePriceScheduleMutation } = priceScheduleApi;
