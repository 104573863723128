import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  EndpointPermission,
  Permission,
  PermissionDataProps,
} from "../domain/Permission.model";
import { createAdapterPermission } from "../adapters/permission.adapter";
import {TableOptions} from "../../shared-kernel/components/Table";
import {appConfig} from "../../config";

const API_URL_UM = appConfig.API_URL_UM;

export const permissionApi = createApi({
    reducerPath: "PermissionApi",
    baseQuery: fetchBaseQuery({
      baseUrl: API_URL_UM,
      prepareHeaders: (headers: Headers) => {
        const APP_TOKEN = "app_token";
        const token = localStorage.getItem(APP_TOKEN);

        if (token) {
          headers.set("authorization", `Bearer ${token}`);
          headers.set("Content-Type", "application/json");
        }

        return headers;
      },
      credentials: "include",
    }),
    tagTypes: ["Permissions"],
    endpoints: (builder) => ({
      getPermissions: builder.query<PermissionDataProps, TableOptions<Permission> | undefined>({
        query: (tableOptions: TableOptions<Permission>) =>{
          let queryParams: string = '';
          if(tableOptions !== undefined && tableOptions.page){
            const page = tableOptions.page - 1;
            queryParams += `page=${page}`;
          }
          if(tableOptions !== undefined && tableOptions.pageSize){
            queryParams += `&pageSize=${tableOptions.pageSize}`;
          }
          if(tableOptions !== undefined && tableOptions.order){
            const parsedOrder = JSON.stringify({
              field: tableOptions.order.field,
              order: tableOptions.order.order
            })
            queryParams += `&order=${parsedOrder}`;
          }
          if(tableOptions !== undefined && tableOptions.search){
            queryParams += `&search=${tableOptions.search}`;
          }
          return `permissions${queryParams !== '' ? `?${queryParams}` : ''}`
        }
          ,
        providesTags: ["Permissions"],
        transformResponse: (response: any) => {
          return {
            data: response.map((p: EndpointPermission) => createAdapterPermission(p)),
            page: 1,
            total: response.length,
          };
        }
      }),
      updatePermission: builder.mutation<any, EndpointPermission>({
        query: (data: EndpointPermission) => ({
          url: `permissions/${data.id}`,
          method: "PUT",
          body: createAdapterPermission(data),
        }),
        invalidatesTags: ["Permissions"],
      }),
      getPermissionById: builder.query<EndpointPermission, string | undefined>({
        query: (permissionId) => `permissions/${permissionId}`,
        providesTags: (result, error, permissionId) => [{ type: 'Permissions', id: permissionId }],
        transformResponse: (response: EndpointPermission) => createAdapterPermission(response),
      }),
      createNewPermission: builder.mutation<any, any>({
        query: (data) => ({
          url: 'permissions/',
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["Permissions"],
      }),
      deletePermission: builder.mutation<any, any>({
        query: (id) => ({
          url: `permissions/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ['Permissions']
      }),
    }),
  });

export const { useGetPermissionsQuery,useGetPermissionByIdQuery,useDeletePermissionMutation,useUpdatePermissionMutation,useCreateNewPermissionMutation } = permissionApi;
