import { createAdapterInventory } from "../../inventory/adapters/inventory.adapter";
import { EndpointProductInventory, ProductInventory } from "../domain/ProductInventory.model";

export const createAdapterProductInventory = (productInventory: EndpointProductInventory) => {
    const formattedProductInventory: ProductInventory = {
        id: productInventory.id,
        productId: productInventory.productId,
        inventory: createAdapterInventory(productInventory.inventory),
        identifier: productInventory.identifier,
        metadata: productInventory.metadata,
        online: productInventory.online,
        enabled: productInventory.enabled
    };
    return formattedProductInventory;
}