import { FieldErrors } from "react-hook-form";
import { InputError } from "../styled-components/InputError";
import { SelectOption } from "../../../models/selectOption.model";
import React from "react";
import translate from "../../../../language/translate";
import {
  SelectLayout,
  StyledOption,
  StyledSelect,
} from "./styled-components/StyledSelect";
import {useIntl} from "react-intl";

const formValidation = (errors: FieldErrors, errorKey: string, defaultErrorMessage: string) => {
  return (
    <InputError show={!!errors[errorKey]}>
      {errors[errorKey]?.message as string ?? defaultErrorMessage}
    </InputError>
  );
};

interface SelectProps {
  id?: string;
  nullValue?: any;
  value?: any;
  register?: any;
  name: string;
  errors?: FieldErrors;
  label?: string;
  options: SelectOption[];
  required?: boolean;
  defaultValue?: any;
  hidden?: boolean;
  onChange?: any;
  disabled?: boolean;
  width?: string;
}

export const Select = ({
  id,
  nullValue,
  value,
  register,
  name,
  errors,
  options,
  required = false,
  hidden,
  onChange,
  disabled = false,
  width
}: SelectProps) => {
  const intl = useIntl();
  const DEFAULT_NULL_VALUE = -1;
  const DEFAULT_ERROR_MESSAGE = intl.formatMessage({ id: 'complete this field' })
  const onChangeAttribute = onChange ? {onChange: onChange} : {};
  return (
    <SelectLayout hidden={hidden} width={width}>
      <StyledSelect required={required} {...register} {...onChangeAttribute} value={value} id={id ?? name} disabled={disabled}>
        <StyledOption value={nullValue ?? DEFAULT_NULL_VALUE}>{translate("none")}</StyledOption>
        {options.map((option, index) => (
          <StyledOption
            key={`${name}_select_option_${index}`}
            value={option.value}
          >
            {option.label}
          </StyledOption>
        ))}
      </StyledSelect>
      {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
    </SelectLayout>
  );
};

export default Select;
