import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from "../domain/Notification.model";

const initialState: Notification = {
  show: false,
  severity: 'info',
  message: ''
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotification: (_state, action: PayloadAction<Notification>) => {
      return action.payload;
    },
    cleanNotification: (_state) => {
      return {
        ...initialState,
        severity: _state.severity
      };
    }
  }
});

export const { setNotification, cleanNotification } = notificationSlice.actions;

export default notificationSlice.reducer;