import {createAdapterProduct} from "../../product/adapters/product.adapter";
import {EndpointPriceSchedule, PriceSchedule} from "../domain/PriceSchedule.model";

export const createAdapterPriceSchedule = (priceSchedule: EndpointPriceSchedule) => {
    const formattedPriceSchedule: PriceSchedule = {
        id: priceSchedule.id,
        product: createAdapterProduct(priceSchedule.product),
        start: priceSchedule.start,
        end: priceSchedule.end,
        price: priceSchedule.price
    };
    return formattedPriceSchedule;
}


export const createAdapterEndpointPriceSchedule = (priceSchedule: PriceSchedule) => {
    const formattedEndpointPriceSchedule: EndpointPriceSchedule = {
        id: priceSchedule.id,
        product: priceSchedule.product,
        start: priceSchedule.start,
        end: priceSchedule.end,
        price: priceSchedule.price
    };
    return formattedEndpointPriceSchedule;
}