import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../shared-kernel/components/Table";
import { appConfig } from "../../config";
import {
  EndpointResort,
  Resort,
  ResortDataProps,
} from "../domain/Resort.model";
import { createAdapterResort } from "../adapters/resort.adapter";

const API_URL = appConfig.API_URL;

const RESORTS_TAG = "Resorts";

export const resortApi = createApi({
  reducerPath: "ResortApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: [RESORTS_TAG],
  endpoints: (builder) => ({
    getResorts: builder.query<
      ResortDataProps,
      TableOptions<Resort> | undefined
    >({
      query: (tableOptions: TableOptions<Resort>) => {
        let queryParams: string = "";
        if (tableOptions !== undefined && tableOptions.page) {
          const page = tableOptions.page;
          queryParams += `page=${page}`;
        }
        if (tableOptions !== undefined && tableOptions.pageSize) {
          queryParams += `&pageSize=${tableOptions.pageSize}`;
        }
        if (tableOptions !== undefined && tableOptions.order) {
          const parsedOrder = JSON.stringify({
            field: tableOptions.order.field,
            order: tableOptions.order.order,
          });
          queryParams += `&orderBy=${parsedOrder}`;
        }
        if (tableOptions !== undefined && tableOptions.search) {
          queryParams += `&search=${tableOptions.search}`;
        }
        return `resorts${queryParams !== "" ? `?${queryParams}` : ""}`;
      },
      providesTags: [RESORTS_TAG],
      transformResponse: (
        response: any,
        _meta,
        params: TableOptions<Resort>
      ) => {
        const page = params?.page ?? 1;
        return {
          data: response.rows.map((p: EndpointResort) =>
            createAdapterResort(p)
          ),
          page,
          total: response.count,
        };
      },
    }),
    updateResort: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `resorts/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [RESORTS_TAG],
    }),
    getResortById: builder.query<EndpointResort, string | undefined>({
      query: (resortId) => `resorts/${resortId}`,
      providesTags: (result, error, resortId) => [
        { type: "Resorts", id: resortId },
      ],
      transformResponse: (response: EndpointResort) =>
        createAdapterResort(response),
    }),
    createResort: builder.mutation<any, any>({
      query: (data) => ({
        url: "resorts/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RESORTS_TAG],
    }),
    deleteResort: builder.mutation<any, any>({
      query: (id) => ({
        url: `resorts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RESORTS_TAG],
    }),
  }),
});

export const {
  useGetResortByIdQuery,
  useGetResortsQuery,
  useCreateResortMutation,
  useUpdateResortMutation,
  useDeleteResortMutation,
} = resortApi;
