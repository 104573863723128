import styled from "styled-components";

export const StyledTopbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 74px;
    background: ${(props) => props.theme.layout.sidebar.background};
    z-index: 100;
    box-shadow: 0 2px 4px ${(props) => `${props.theme.layout.sidebar.shadowColor}40`};
    padding: 0 36px;
`