import { SidebarNavLogout } from "../styled-components/SidebarNavLogout";
import Person2Icon from "@mui/icons-material/Person2";
import { useNavigate } from "react-router";

interface DropMenuItemProps {
  label: string;
  link: string;
  dropdownToggle: () => void;
}

function DropMenuItem({ label, link, dropdownToggle }: DropMenuItemProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    dropdownToggle();
    navigate(link);
  };

  return (
    <SidebarNavLogout onClick={handleClick}>
      <Person2Icon />
      <span>{label}</span>
    </SidebarNavLogout>
  );
}

export default DropMenuItem;