import styled from "styled-components";

export const StyledSidebarNavMenu = styled.ul`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    @media (min-width: 1024px) {
        display: flex;
        padding: 0;
    }
`