import styled from "styled-components";

interface TablePaginationProps {
    show?: boolean;
}

interface StyledTablePaginationBoxProps {
    hiddenMobile?: boolean;
}

interface StyledTablePaginationPageNumberProps {
    ref?: any;
}

export const StyledTablePagination = styled.div<TablePaginationProps>`
    display: ${props => props.show ? "flex" : "none"};
    color: ${props => props.theme.table.textColor};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: auto;
    svg {
        cursor: pointer;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    @media (min-width: 400px) {
        flex-direction: row;
    }
`

export const StyledTablePaginationPageNumber = styled.input<StyledTablePaginationPageNumberProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 45px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.5);
    margin: 0;
    outline: none;
`

export const StyledTablePaginationSelect = styled.select`
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.5);
    background-color: ${props => props.theme.table.bodyBackground};
    margin: 0 10px;
    outline: none;
`

export const StyledTablePaginationBox = styled.div<StyledTablePaginationBoxProps>`
    display: ${props => props.hiddenMobile ? "none" : "flex"};
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    @media (min-width: 400px) {
        display: flex;
        margin: 0;
    }
`
export const StyledTablePaginationDivider = styled.span`
    margin: 10px;
    display: none;
    @media (min-width: 400px) {
        display: inherit;
    }
`