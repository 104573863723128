import styled from "styled-components";

interface TableRow {
  colorLine?: string;
  background?: string;
  hoverRow?: string; 
}

interface TableHeaderRow {
  colorLine?: string;
  background?: string;
}

export const StyledTableRow = styled.tr<TableRow>`
  display: block;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  background-color: ${(props) => `${props.theme.table.rowBackground}50`};
  border: 1px solid ${(props) => props.theme.table.rowBackground};
  margin: 10px 0;
  
  @media (min-width: 600px) {
    display: table-row;
    margin: 0;
    border: 1px solid ${(props) => props.theme.table.rowBackground};
    td {
      border: 1px solid ${(props) => props.theme.table.cellColorLine};
      background: ${(props) =>
        props.background
          ? props.background
          : `${props.theme.table.rowBackground}10`};
      border-radius: 0;
    }
    td:first-child {
      border-left: 1px solid
        ${(props) =>
          props.colorLine ? props.colorLine : props.theme.table.cellColorLine};
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-right: 1px solid
        ${(props) =>
          props.colorLine ? props.colorLine : props.theme.table.cellColorLine};
      border-radius: 0 8px 8px 0;
    }
    &:hover {
      background: ${(props) => `${props.theme.table.hoverRow}15`};;
    }
  }
`;

export const StyledTableHeaderRow = styled.tr<TableHeaderRow>`
  display: table-row;
  width: 100%;
  vertical-align: middle;
  height: fit-content;
  th {
    border: 1px solid ${(props) =>
            props.colorLine ? props.colorLine : props.theme.table.headerColorLine};
    background: ${(props) =>
            props.background
                    ? props.background
                    : `${props.theme.table.rowHeaderBackground}`};
    border-radius: 0;
  }
  th:first-child {
    border-left: 1px solid
    ${(props) =>
            props.colorLine ? props.colorLine : props.theme.table.headerColorLine};
    border-radius: 8px 0 0 8px;
  }
  th:last-child {
    border-right: 1px solid
    ${(props) =>
            props.colorLine ? props.colorLine : props.theme.table.headerColorLine};
    border-radius: 0 8px 8px 0;
  }
`;
