import {TableDataProps} from "../../shared-kernel/components/Table";
import {EndpointRole, Role} from "../../role/domain/Role.model";

export type User = {
    id?: string,
    username: string,
    password?: string,
    email: string,
    role?: Role,
    resort?: string;
    firstname?:string;
    lastname?:string;
}

export type EndpointUser = {
    id?: string,
    username: string,
    password?: string,
    email: string,
    role?: EndpointRole,
    metadata?: any;
}

export type EndpointUserList = {
    rows: Array<EndpointUser>;
    page?: number;
    total?: number;
}

export interface UserDataProps extends TableDataProps<User>{
    data: User[];
    total?: number;
}

export const NOT_RESORT_ID = "-1";
export const NOT_ROLE_ID = "-1";