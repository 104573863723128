import styled from "styled-components";

interface StyledAddFilterContainerProps {
    show: boolean;
}

export const StyledAddFilterContainer = styled.div<StyledAddFilterContainerProps>`
  position: absolute;
  top: 90%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  max-height: ${props => props.show ? '60vh' : '0px'};
  z-index: 8;
  background: ${props => props.theme.base.main};
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 8px 0 8px 8px;
  overflow-y: hidden;
  transition: all 150ms ease-in-out; 
`

export const StyledAddFilterOptionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.secondary.main};
  border-radius: 8px;
  padding: 10px;
`