import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../config";
import {TableOptions} from "../../../shared-kernel/components/Table";
import {createAdapterCategory, createAdapterToEndpointCategory} from "../adapters/category.adapter";
import {Category, CategoryDataProps, EndpointCategory} from "../domain/Category.model";


const API_URL = appConfig.API_URL;

const CATEGORIES_TAG = "Categories";

export const categoryApi = createApi({
    reducerPath: "CategoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [CATEGORIES_TAG],
    endpoints: (builder) => ({
        getCategories: builder.query<CategoryDataProps, TableOptions<Category> | undefined>({
            query: (tableOptions: TableOptions<Category>) =>{
                let queryParams: string = '';
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `categories${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [CATEGORIES_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.map((category: EndpointCategory) => createAdapterCategory(category)),
                    page: 1,
                    total: response.length,
                };
            }
        }),
        updateCategory: builder.mutation<any, EndpointCategory>({
            query: (data: EndpointCategory) => {
                const bodyData = createAdapterCategory(data);
                return {
                    url: `categories/${data.id}`,
                    method: "PUT",
                    body: {
                        ...createAdapterToEndpointCategory(bodyData),
                        applicationId: appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [CATEGORIES_TAG],
        }),
        getCategoryById: builder.query<EndpointCategory, string | undefined>({
            query: (id) => `categories/${id}?applicationId=${appConfig.APPLICATION_ID}`,
            providesTags: (result, error, id) => [{ type: 'Categories', id: id }],
            transformResponse: (response: EndpointCategory) => createAdapterCategory(response),
        }),
        createCategory: builder.mutation<any, any>({
            query: (data) => ({
                url: 'categories/',
                method: "POST",
                body: {
                    ...createAdapterToEndpointCategory(data),
                    applicationId: appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [CATEGORIES_TAG],
        }),
        deleteCategory: builder.mutation<any, any>({
            query: (id) => ({
                url: `categories/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [CATEGORIES_TAG]
        }),
    }),
});

export const { useGetCategoryByIdQuery, useGetCategoriesQuery, useCreateCategoryMutation, useUpdateCategoryMutation, useDeleteCategoryMutation } = categoryApi;
