import styled from "styled-components";

export const StyledTableAddButtonName = styled.span`
  display: none;
  @media screen and (min-width: 600px) {
    display: inherit;
  }
`

export const StyledTableFreeActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  height: auto;
  width: auto;
`

export const StyledTableFreeAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 8px 12px 8px 8px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.2);
  background: none;
  cursor: pointer;
  text-transform: uppercase;
  background: ${props => props.theme.table.addButtonBackground};
  color: ${props => props.theme.table.addButtonTextColor};
  transition: all 300ms ease-in-out;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: auto;
  }
`