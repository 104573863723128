import styled from "styled-components";

interface TableBodyProps {
    background?: string;
}

export const StyledTableBody = styled.tbody<TableBodyProps>`
    width: 100%;
    height: 100%;
    background-color: ${props => props.background ? props.background : props.theme.table.bodyBackground};
`