import {StyledTab, StyledTabDefaultIcon, StyledTabIcon, StyledTopbarTabs} from "../styled-components/StyledTopbarTabs";
import {SidebarMenu} from "../domain/sidebarNav";
import {useLocation} from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";

interface TopbarTabsProps {
    menus: SidebarMenu[] | undefined
}

const TopbarTabs = ({menus}: TopbarTabsProps) => {
    const location = useLocation();
    const parentMenu = menus?.find(menu => menu.submenus.find(submenu => submenu.action === location.pathname));
    return (
        parentMenu && parentMenu.submenus.length > 0 ?
        <StyledTopbarTabs>
            {
                parentMenu.submenus.map((submenu: SidebarMenu, index: number) => {
                    return (
                        <StyledTab key={`submenu_tab_${index}`} to={submenu.action}>
                            {
                                submenu.icon ?
                                    <StyledTabIcon src={submenu.icon} alt={`submenu_tab_icon_${index}`} />
                                    :
                                    <StyledTabDefaultIcon>
                                        <FolderIcon />
                                    </StyledTabDefaultIcon>
                            }
                            <span>{submenu.label}</span>
                        </StyledTab>
                    )
                })
            }
        </StyledTopbarTabs>
            : null
    );
};
export default TopbarTabs;
