import {
  StyledSidebarNavMenuItemButton,
  StyledSidebarNavMenuItemName,
} from "../styled-components/StyledSidebarNavMenuItemLink";
import { StyledMenuArrow } from "../../../../../menu/styled-components/StyledMenuArrow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import {
  SidebarNavMenuItemContainer,
  StyledSidebarNavSubmenusContainer,
} from "../styled-components/SidebarNavMenuItemContainer";
import React, { useContext, useEffect, useState } from "react";
import SidebarNavMenuItem from "./SidebarNavMenuItem";
import { SidebarContext } from "../../../Sidebar/SidebarContext";
import { SidebarMenu } from "../domain/sidebarNav";

function SidebarNavMenuItemGroup({
  item,
  showname,
}: {
  item: SidebarMenu;
  showname: boolean;
}) {
  const [expand, setExpand] = useState<boolean>(false);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const handleExpand = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    if (!sidebarToggle) {
      toggleSidebar();
    }
    setExpand(!expand);
  };

  useEffect(() => {
    if (!showname && expand) {
      setExpand(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showname]);

  return (
    <SidebarNavMenuItemContainer show={showname} onClick={handleExpand}>
      <StyledSidebarNavMenuItemButton expand={expand} $showname={showname}>
        {item.icon ? item.icon : <FolderIcon />}
        <StyledSidebarNavMenuItemName $showname={showname}>
          {item.label}
        </StyledSidebarNavMenuItemName>
        <StyledMenuArrow show={showname}>
          <ArrowForwardIosIcon />
        </StyledMenuArrow>
      </StyledSidebarNavMenuItemButton>
      <StyledSidebarNavSubmenusContainer expand={expand}>
        {item.submenus.map((subitem, index) => (
          <SidebarNavMenuItem
            key={`subitem_${index}_${subitem.label}`}
            item={subitem}
            showname={showname}
          />
        ))}
      </StyledSidebarNavSubmenusContainer>
    </SidebarNavMenuItemContainer>
  );
}

export default SidebarNavMenuItemGroup;
