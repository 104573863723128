import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../../config";

const API_URL = appConfig.API_URL;

interface ProductPercentageParams {
    sku: string;
    from: string | undefined;
    to: string | undefined;
    applicationId: string;
}

interface ProductStatistics {
    from: string | undefined;
    to: string | undefined;
    applicationId: string;
}

export const resumeApi = createApi({
    reducerPath: "ResumeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: ['ProductPercentage', 'ProductSales', 'ProductOccupation'],
    endpoints: (builder) => ({
        getProductPercentage: builder.query<any, ProductPercentageParams>({
            query: ({sku, from, to, applicationId}) => `statistics/product?sku=${sku}&from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: (result, error, params) => [{ type: 'ProductPercentage', id: params.sku }]
        }),
        getProductSales: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/productssales?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: ['ProductSales']
        }),
        getProductOccupation: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/productsoccupation?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: ['ProductOccupation']
        })
    }),
});

export const { useGetProductPercentageQuery, useGetProductSalesQuery, useGetProductOccupationQuery } = resumeApi;
