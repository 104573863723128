import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../config";
import {Brand, BrandDataProps, EndpointBrand} from "../domain/Brand.model";
import {TableOptions} from "../../../shared-kernel/components/Table";
import {createAdapterBrand} from "../adapters/brand.adapter";


const API_URL = appConfig.API_URL;

const BRANDS_TAG = "Brands";

export const brandApi = createApi({
    reducerPath: "BrandApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [BRANDS_TAG],
    endpoints: (builder) => ({
        getBrands: builder.query<BrandDataProps, TableOptions<Brand> | undefined>({
            query: (tableOptions: TableOptions<Brand>) =>{
                let queryParams: string = '';
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `brands${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [BRANDS_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.map((brand: EndpointBrand) => createAdapterBrand(brand)),
                    page: 1,
                    total: response.length,
                };
            }
        }),
        updateBrand: builder.mutation<any, EndpointBrand>({
            query: (data: EndpointBrand) => {
                const bodyData = createAdapterBrand(data);
                return {
                    url: `brands/${data.id}`,
                    method: "PUT",
                    body: {
                        ...bodyData,
                        applicationId: appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [BRANDS_TAG],
        }),
        getBrandById: builder.query<EndpointBrand, string | undefined>({
            query: (id) => `brands/${id}?applicationId=${appConfig.APPLICATION_ID}`,
            providesTags: (result, error, id) => [{ type: 'Brands', id: id }],
            transformResponse: (response: EndpointBrand) => createAdapterBrand(response),
        }),
        createBrand: builder.mutation<any, any>({
            query: (data) => ({
                url: 'brands/',
                method: "POST",
                body: {
                    ...data,
                    applicationId: appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [BRANDS_TAG],
        }),
        deleteBrand: builder.mutation<any, any>({
            query: (id) => ({
                url: `brands/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [BRANDS_TAG]
        }),
    }),
});

export const { useGetBrandByIdQuery, useGetBrandsQuery, useCreateBrandMutation, useUpdateBrandMutation, useDeleteBrandMutation } = brandApi;
