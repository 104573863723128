import styled from "styled-components";

export const TableContainer = styled.div`
    width: 100%;
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  height: 100%;
`

export const StyledTableZone = styled.div`
  max-width: calc(100vw - 20px);
  display: flex;
  flex: 1;
  flex-flow: column;
  overflow: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primary.light};
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.secondary.light};
  }
`


export const StyledActionsZone = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
`

export const StyledActionsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
`

export const StyledActionsSectionCenter = styled(StyledActionsSection)`
  flex-wrap: wrap;
`

export const StyledActionsSectionRight = styled(StyledActionsSection)`
  justify-content: flex-end;
`