import { StyledTableHead } from "../styled-components/StyledTableHead";
import { TableColumn } from "../domain/columns.model";
import {
  StyledHeaderLabel, StyledHeaderLabelActions,
  StyledTableHeaderCell,
  StyledTableHeaderCellActions,
} from "../styled-components/StyledTableCell";
import { StyledTableHeaderRow } from "../styled-components/StyledTableRow";
import {
  SortColumnProps,
} from "../domain/table.model";
import TableFieldOrder from "./TableFieldOrder";
import {TableColumnSelector} from "./TableColumnSelecter/TableColumnSelector";
import {ColumnSelectable} from "../tableUtils/columnSelector.hook";
import { StyledExchangeColumnsIcon} from "../styled-components/StyledExchangeColumns";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface TableHeadProps<T> {
  tableName: string;
  columns: TableColumn<T>[];
  sortColumn?: SortColumnProps;
  onShortColumnChange: (newSort: SortColumnProps) => void;
  initialColumns: ColumnSelectable[];
  selectedColumns: ColumnSelectable[];
  addColumn: (column: ColumnSelectable) => void;
  removeColumn: (column: ColumnSelectable) => void;
  columnsOrder: string[];
  onExchangeColumns: (a: string, b: string) => void;
}

function TableHead<T>({
  tableName,
  columns,
  sortColumn,
  onShortColumnChange,
  initialColumns,
  selectedColumns,
  addColumn,
  removeColumn,
  columnsOrder,
  onExchangeColumns
}: TableHeadProps<T>) {
  const availableColumns = columns ?? [];

  const orderedColumns = columnsOrder.map((columnField) => {
    return availableColumns.find(availableColumn => availableColumn.field === columnField);
  })

  const filteredColumns = orderedColumns.filter(colToFilter => !!selectedColumns.find(selected => selected.field === colToFilter?.field));

  const handleExchangeColumnsBack = (e: any) => {
    const { name, prevname } = e.currentTarget.dataset;
    onExchangeColumns(prevname, name);
  }

  const handleExchangeColumnsForward = (e: any) => {
    const { name, postname } = e.currentTarget.dataset;
    onExchangeColumns(name, postname);
  }

  return (
    <StyledTableHead>
      <StyledTableHeaderRow>
        {
          filteredColumns.map((col: TableColumn<T> | undefined, index: number) => (
              col ?
                  !col.hidden ?
                  <StyledTableHeaderCell
                      key={`header_cell_${index}`}
                      width={col.width}
                      hidden={col.hidden}
                      textAlign={col.headerAlign}
                      color={col.color}
                      background={col.background}
                  >
                    <StyledHeaderLabel>
                      {col.label}
                      <StyledHeaderLabelActions>
                        {
                          index !== 0 ?
                              <StyledExchangeColumnsIcon onClick={handleExchangeColumnsBack} data-prevname={filteredColumns[index - 1]?.field} data-name={col.field} >
                                <ArrowLeftIcon />
                              </StyledExchangeColumnsIcon>
                              : null
                        }
                        {col.order ? (
                            <TableFieldOrder<T>
                                column={col}
                                sortColumn={sortColumn}
                                onShortColumnChange={onShortColumnChange}
                            />
                        ) : null}
                        {
                          index !== filteredColumns.length - 1 ?
                              <StyledExchangeColumnsIcon onClick={handleExchangeColumnsForward} data-postname={filteredColumns[index + 1]?.field} data-name={col.field} >
                                <ArrowRightIcon />
                              </StyledExchangeColumnsIcon>
                              : null
                        }
                      </StyledHeaderLabelActions>

                    </StyledHeaderLabel>
                  </StyledTableHeaderCell>
                  : null
              : null
            ))
        }
        <StyledTableHeaderCellActions><TableColumnSelector<T> tableName={tableName} columns={columns} initialColumns={initialColumns} selectedColumns={selectedColumns} addColumn={addColumn} removeColumn={removeColumn} /></StyledTableHeaderCellActions>
      </StyledTableHeaderRow>
    </StyledTableHead>
  );
}

export default TableHead;
