import { StyledSidebarNavSwitch } from "../styled-components/StyledSidebarNavSwitch";
import { StyledTopbarContainer } from "../styled-components/StyledTopbarContainer";
import Profile from "./Profile";
import Breadcrumb from "../../../Breadcrumb/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { appConfig } from "../../../../../config";

const TopbarNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const crumb = location.pathname
    .replace(/[?#].*$/, "")
    .split("/")
    .filter((segment) => segment !== "")
    .map((segment, index, array) => ({
      label: segment,
      link: "/" + array.slice(0, index + 1).join("/"),
    }));

  const goToHome = () => {
    navigate(appConfig.HOME_PAGE);
  };

  const goToResume = () => {
    navigate(appConfig.RESUME_PAGE);
  };

  const goToBooking = () => {
    navigate(appConfig.BOOKING_PAGE);
  };

  return (
    <StyledTopbarContainer>
      <StyledSidebarNavSwitch>
        <HomeIcon onClick={goToHome} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="29"
          viewBox="0 0 512 512"
          onClick={goToBooking}
        >
          <path
            fill="white"
            d="M480 128a64 64 0 0 0-64-64h-16V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 0 0 368 48v16H144V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 0 0 112 48v16H96a64 64 0 0 0-64 64v12a4 4 0 0 0 4 4h440a4 4 0 0 0 4-4ZM32 416a64 64 0 0 0 64 64h320a64 64 0 0 0 64-64V179a3 3 0 0 0-3-3H35a3 3 0 0 0-3 3Zm344-208a24 24 0 1 1-24 24a24 24 0 0 1 24-24m0 80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m-80-80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m0 80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m0 80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m-80-80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m0 80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m-80-80a24 24 0 1 1-24 24a24 24 0 0 1 24-24m0 80a24 24 0 1 1-24 24a24 24 0 0 1 24-24"
          />
        </svg>

        <svg
          width="30"
          height="29"
          viewBox="0 0 30 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goToResume}
        >
          <path
            d="M2.51259 26.9286V27.4286H3.01259H29.4412V28.5H3.01259C2.59582 28.5 2.19612 28.3344 1.90142 28.0397C1.60672 27.745 1.44116 27.3453 1.44116 26.9286V0.5H2.51259V26.9286Z"
            fill="white"
            stroke="white"
          />
          <path
            d="M11.9435 14.6138L5.75612 21.4326L5.02798 20.6302L11.9778 12.9607L11.9789 12.9594C12.0275 12.9054 12.0827 12.8655 12.1397 12.8393C12.1966 12.8132 12.2556 12.8006 12.3138 12.8006C12.372 12.8006 12.431 12.8132 12.4879 12.8393C12.5449 12.8655 12.6001 12.9054 12.6487 12.9594L12.6497 12.9605L16.9186 17.6761L17.2889 18.085L17.6596 17.6765L25.2124 9.353L25.971 8.51701H24.8421H21.7697V7.32373H27.7354V14.0002H26.7452V10.5633V9.26703L25.8747 10.2275L17.6253 19.3296L17.6242 19.3309C17.5756 19.3849 17.5204 19.4248 17.4634 19.451L17.672 19.9054L17.4634 19.451C17.4065 19.4771 17.3475 19.4898 17.2893 19.4898C17.2311 19.4898 17.1721 19.4771 17.1152 19.451C17.0582 19.4248 17.003 19.3849 16.9544 19.3309L16.9535 19.3298L12.6845 14.6143L12.3142 14.2053L11.9435 14.6138Z"
            fill="white"
            stroke="white"
          />
        </svg>
        <Breadcrumb breadcrumbList={crumb} />
      </StyledSidebarNavSwitch>
      <Profile />
    </StyledTopbarContainer>
  );
};
export default TopbarNav;
