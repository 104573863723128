import { StyledSidebarNavMenu } from "../styled-components/StyledSidebarNavMenu";
import { SidebarAttributes } from "../domain/sidebarNav";
import { FC } from "react";
import SidebarNavMenuItem from "./SidebarNavMenuItem";

const SidebarNav: FC<SidebarAttributes> = ({ data, isSuccess, openNav }) => {
  return (
    <StyledSidebarNavMenu>
      {isSuccess
        ? data?.map((item, index) => (
            !item.parentMenu ?
                <SidebarNavMenuItem
                    item={item}
                    showname={openNav}
                    key={`item_${index}_${item.name}`}
                />
                : null
          ))
        : null}
    </StyledSidebarNavMenu>
  );
};

export default SidebarNav;
