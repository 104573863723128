import styled from "styled-components";

interface SidebarNavMenuItemContainerProps {
  show: boolean;
}

export const SidebarNavMenuItemContainer = styled.li<SidebarNavMenuItemContainerProps>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  transition: all 150ms ease-in-out;
  flex-direction: column;
  height: fit-content;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  &:hover {
    opacity: 0.65;
  }
`;
interface StyledSidebarNavSubmenusContainerProps {
  expand: boolean;
}
export const StyledSidebarNavSubmenusContainer = styled.ul<StyledSidebarNavSubmenusContainerProps>`
  position: relative;
  width: 100%;
  /*max-height: ${(props) => (props.expand ? "100%" : '0px')};*/
  max-height: ${(props) => (props.expand ? "100vh" : '0px')};
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 150ms ease-in-out;
  margin-left: 30px;
  @media (min-width: 1024px) {
    display: flex;
    padding: 0;
  }
`;
