import styled from "styled-components";

export const StyledSidebarNavSwitch = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  transition: all 200ms ease-in-out;
  gap: 25px;
  svg {
    transition: all 200ms ease-in-out;
    color: ${(props) => props.theme.layout.sidebar.textColor};
    width: 29px;
    height: 29px;
  }

  @media (min-width: 1024px) {
    svg:hover {
      transform: scale(1.1);
    }
  }
`;
