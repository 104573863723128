import {
  StyledCancelIcon,
  StyledInputSearch,
  StyledInputSearchContainer,
} from "./styled-components/StyledInputSearch";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import ClearIcon from "@mui/icons-material/Clear";
import { Tooltip } from "@mui/material";

interface TableSearchProps<T> {
  search?: string;
  onChangeSearch?: (newSearch: string) => void;
  haveFilters: boolean;
  columns: (keyof T)[];
  tooltipLabels?: string[];
}

function TableSearch<T>({
  search,
  onChangeSearch,
  haveFilters,
  columns,
  tooltipLabels,
}: TableSearchProps<T>) {
  const intl = useIntl();
  const SEARCH_DELAY_REQUEST_MS: number = 1000;
  const [inputSearch, setInputSearch] = useState<string>("");
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newSearchTerm = e.target.value;
    setInputSearch(newSearchTerm);

    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    const newTimer = setTimeout(() => {
      if (onChangeSearch) {
        onChangeSearch(newSearchTerm);
      }
    }, SEARCH_DELAY_REQUEST_MS);
    setSearchTimer(newTimer);
  };

  const clearSearch = () => {
    setInputSearch("");
    if (onChangeSearch) {
      onChangeSearch("");
    }
  };

  const labels = tooltipLabels
    ? `<div><span>${intl.formatMessage({
        id: "searcheable columns",
      })}<span><br>
  - ${tooltipLabels.map((label: string) => label).join("<br>- ")}</div>`
    : "";

  return (
    <Tooltip
      title={
        tooltipLabels ? (
          <div dangerouslySetInnerHTML={{ __html: labels }} />
        ) : null
      }
    >
      <StyledInputSearchContainer haveFilters={haveFilters}>
        <StyledInputSearch
          value={inputSearch}
          onChange={handleChange}
          placeholder={`${intl.formatMessage({
            id: "search",
          })}...`}
        />
        <StyledCancelIcon onClick={clearSearch}>
          <ClearIcon />
        </StyledCancelIcon>
      </StyledInputSearchContainer>
    </Tooltip>
  );
}

export default TableSearch;
