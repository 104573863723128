import { useRoutes } from "react-router-dom";
import router from "./router";
import GlobalStyles from "./styled-components/GlobalStyles";
import { Provider } from "react-redux";
import { SidebarProvider } from "./shared-kernel/components/Sidebar/SidebarContext";
import "nprogress/nprogress.css";
import  LanguageProvider  from "./language/presentation/LanguageProvider";
import ThemeProviderWrapper from "./theme/presentation/ThemeProvider";
import { store } from "./store";
import NotificationBar from "./shared-kernel/components/NotificationBar/NotificationBar";
import {ErrorBoundary} from "react-error-boundary";



function App() {
  const content = useRoutes(router);
  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
        <LanguageProvider>
          <SidebarProvider>
            <ThemeProviderWrapper>
              <ErrorBoundary fallback={<div>Algo salio mal</div>}>
                {content}
                <NotificationBar />
              </ErrorBoundary>
            </ThemeProviderWrapper>
          </SidebarProvider>
        </LanguageProvider>
      </Provider>
    </>
  );
}

export default App;
