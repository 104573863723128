import { FieldErrors } from "react-hook-form";
import { InputError } from "../styled-components/InputError";
import { SelectOption } from "../../../models/selectOption.model";
import React from "react";
import {
  SelectLayout,
  StyledOption,
  StyledSelect,
} from "./styled-components/StyledSelect";
import translate from "../../../../language/translate";

const formValidation = (errors: FieldErrors, errorKey: string) => {
  return (
    <InputError show={!!errors[errorKey]}>
      {errors[errorKey]?.message as string}
    </InputError>
  );
};

interface SelectProps {
  register: any;
  name: string;
  errors?: FieldErrors;
  label?: string;
  options: SelectOption[];
  required?: boolean;
  defaultValue?: any;
  hidden?: boolean;
}

export const MultipleSelect = ({
  register,
  name,
  errors,
  options,
  required = false,
  hidden,
}: SelectProps) => {
  return (
    <SelectLayout hidden={hidden}>
      <StyledSelect multiple required={required} {...register}>
        {options.map((option, index) => (
          <StyledOption
            key={`${name}_select_option_${index}`}
            value={option.value}
          >
            {option.label}
          </StyledOption>
        ))}
      </StyledSelect>
      {errors && formValidation(errors, name)}
      <small>{translate('hold down the Ctrl button to select multiple options')}</small>
    </SelectLayout>
  );
};

export default MultipleSelect;
