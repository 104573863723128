import { FC, useState, createContext } from 'react';
type SidebarContextProps = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

interface Props {
  children?: React.ReactNode
}

export const SidebarContext = createContext<SidebarContextProps>(
  {} as SidebarContextProps
);

export const SidebarProvider: FC<Props> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
