import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {createAdapterEndpointInventory, createAdapterInventory} from "../adapters/inventory.adapter";
import {appConfig} from "../../../config";
import {EndpointInventory, Inventory, InventoryDataProps} from "../domain/Inventory.model";
import {TableOptions} from "../../../shared-kernel/components/Table";
import {ResortApplication} from "../../../resort/domain/Resort.model";

const API_URL = appConfig.API_URL;

const INVENTORIES_TAG = "Inventories";

export const inventoryApi = createApi({
    reducerPath: "InventoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [INVENTORIES_TAG],
    endpoints: (builder) => ({
        getInventories: builder.query<InventoryDataProps, TableOptions<Inventory> & ResortApplication | undefined>({
            query: (tableOptions) =>{
                const applicationId = tableOptions?.applicationId ?? appConfig.APPLICATION_ID;
                let queryParams: string = `applicationId=${applicationId}`;
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `&page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `inventories/list${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [INVENTORIES_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.data.map((inventory: EndpointInventory) => createAdapterInventory(inventory)),
                    page: response.page,
                    total: response.total,
                };
            }
        }),
        updateInventory: builder.mutation<any, any>({
            query: (data: any) => {
                const bodyData = createAdapterEndpointInventory(data);
                return {
                    url: `inventories/${data.id}`,
                    method: "PUT",
                    body: {
                        ...bodyData,
                        applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [INVENTORIES_TAG],
        }),
        getInventoryById: builder.query<EndpointInventory, string | undefined>({
            query: (id) => `inventories/${id}?applicationId=${appConfig.APPLICATION_ID}`,
            providesTags: (result, error, id) => [{ type: 'Inventories', id: id }],
            transformResponse: (response: EndpointInventory) => createAdapterInventory(response),
        }),
        createInventory: builder.mutation<any, any>({
            query: (data: any) => ({
                url: 'inventories/',
                method: "POST",
                body: {
                    ...createAdapterEndpointInventory(data),
                    applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [INVENTORIES_TAG],
        }),
        deleteInventory: builder.mutation<any, any>({
            query: (id) => ({
                url: `inventories/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [INVENTORIES_TAG]
        }),
    }),
});

export const { useGetInventoriesQuery, useGetInventoryByIdQuery, useCreateInventoryMutation, useUpdateInventoryMutation, useDeleteInventoryMutation } = inventoryApi;
