import styled from "styled-components";

export const StyledColumnsSelectorContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledColumnsSelectorHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

interface StyledAddColumnsSelectorContainerProps {
    show: boolean;
}

export const StyledAddColumnsSelectorContainer = styled.div<StyledAddColumnsSelectorContainerProps>`
  position: absolute;
  top: 90%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  max-height: ${props => props.show ? '60vh' : '0px'};
  z-index: 8;
  background: ${props => props.theme.base.main};
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 8px 0 8px 8px;
  overflow-y: hidden;
  transition: all 150ms ease-in-out; 
`

export const StyledFormTableColumnsSelector = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: inherit;
  padding: 10px 20px;
  overflow-y: auto;
  color: ${props => props.theme.table.textColor};
  text-transform: initial;
`

export const StyledFormTableColumnsSelectorHeader = styled.span`
  position: relative;
  color: ${props => props.theme.table.textColor};
  border-bottom: 1px solid rgba(0,0,0,0.15);
  padding: 4px 0;
  width: 100%;
  text-align: left;
  text-transform: initial;
  font-size: 1.15rem;
`