const themeColors = {
  primary: "#ae192a",
  secondary: "#FFFFFF",
  success: "#57CA22",
  warning: "#FFA319",
  error: "#CC0000",
  info: "#33C2FF",
  black: "#131720",
  white: "#ffffff",
  base: "#131720",
  primaryAlt: "#ffa736",
  transparent: "transparent",
};

export const darkTheme = {
  primary: {
    lighter: `${themeColors.primary}15`,
    light: `${themeColors.primary}25`,
    main: themeColors.primary,
    dark: themeColors.primary,
  },
  secondary: {
    lighter: `${themeColors.secondary}15`,
    light: `${themeColors.secondary}25`,
    main: themeColors.secondary,
    dark: themeColors.secondary,
  },
  base: {
    lighter: `${themeColors.base}15`,
    light: `${themeColors.base}25`,
    main: themeColors.base,
    dark: themeColors.base,
  },
  black: {
    lighter: `${themeColors.black}15`,
    light: `${themeColors.black}25`,
    main: themeColors.black,
    dark: themeColors.black,
  },
  success: {
    lighter: `${themeColors.success}15`,
    light: `${themeColors.success}25`,
    main: themeColors.success,
    dark: themeColors.success,
  },
  warning: {
    lighter: `${themeColors.warning}15`,
    light: `${themeColors.warning}25`,
    main: themeColors.warning,
    dark: themeColors.warning,
  },
  error: {
    lighter: `${themeColors.error}15`,
    light: `${themeColors.error}25`,
    main: themeColors.error,
    dark: themeColors.error,
  },
  info: {
    lighter: `${themeColors.info}15`,
    light: `${themeColors.info}25`,
    main: themeColors.info,
    dark: themeColors.info,
  },
  gradients: {
    blue1: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
    blue2: "linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)",
    blue3: "linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)",
    blue4: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)",
    blue5: "linear-gradient(135deg, #97ABFF 10%, #123597 100%)",
    orange1: "linear-gradient(135deg, #FCCF31 0%, #F55555 100%)",
    orange2: "linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)",
    orange3: "linear-gradient(120deg, #f6d365 0%, #fda085 100%)",
    purple1: "linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)",
    purple3: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
    pink1: "linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)",
    pink2: "linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)",
    green1: "linear-gradient(135deg, #FFF720 0%, #3CD500 100%)",
    green2: "linear-gradient(to bottom, #00b09b, #96c93d)",
    black1: "linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)",
    black2: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
  },
  shadows: {
    success:
      "0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)",
    error:
      "0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)",
    info: "0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)",
    primary:
      "0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)",
    warning:
      "0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)",
    card: "0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
    cardSm:
      "0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)",
    cardLg:
      "0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)",
  },
  layout: {
    general: {
      bodyBg: themeColors.black,
      bodyTextColor: themeColors.white,
    },
    breadcrum: {
      backgroundActive: themeColors.primary,
      textColorActive: themeColors.white,
      background: themeColors.secondary,
      textColor: themeColors.black,
    },
    login: {
      title: themeColors.primary,
      inputBg: themeColors.white,
      inputColor: themeColors.black,
      buttonBg: themeColors.primary,
      buttonColor: themeColors.white,
      background: themeColors.black,
      backgroundBox: "#6b6969",
      borderBox: themeColors.white,
    },
    sidebar: {
      background: themeColors.black,
      textColor: themeColors.white,
      shadowColor: themeColors.white,
      dividerBg: "#f2f5f9",
      menuItemColor: "#242E6F",
      menuItemColorActive: themeColors.primaryAlt,
      menuItemBg: themeColors.white,
      menuItemBgActive: "#f2f5f9",
      menuItemIconColor: themeColors.secondary,
      menuItemIconColorActive: themeColors.primary,
      menuItemHeadingColor: themeColors.secondary,
    },
  },
  table: {
    headerBackground: "#7a84a4",
    bodyBackground: themeColors.transparent,
    textColor: themeColors.white,
    headerColorLine: "#828cab",
    cellColorLine: "#a7aec3",
    rowBackground: "#929498",
    rowHeaderBackground: "#6d717e",
    hoverRow: themeColors.black,
    selectedOrderColor: themeColors.primaryAlt,
    unselectedOrderColor: themeColors.black,
    tableActionsBackground: "#575757",
    tableActionsTextColor: themeColors.black,
    addButtonBackground: themeColors.primaryAlt,
    addButtonTextColor: themeColors.base,
  },
  inputs: {
    select: {
      background: themeColors.base,
      color: themeColors.white,
      hoverBackground: `${themeColors.black}25`,
      hoverColor: themeColors.base,
      selectedBackground: `${themeColors.black}50`,
      selectedColor: themeColors.base,
    },
  },
  formSidebar: {
    background: themeColors.base,
    submitButtonColor: themeColors.primary,
    submitButtonBackground: themeColors.base,
    cancelButtonColor: themeColors.error,
    cancelButtonBackground: themeColors.base,
    extraButtonColor: themeColors.info,
    extraButtonBackground: themeColors.base
  },
  home: {
    background: "#9E9E9E",
    backgroundBox: themeColors.base,
  },
  status: {
    comingSoon: {
      background: themeColors.black,
      textTime: "#dfaa66",
      numbersTime: themeColors.white,
    },
    notFound: {
      text: themeColors.white,
      background: themeColors.black,
    },
    link: {
      text: themeColors.white,
    },
  },
  indicators: {
    label: {
      textLabel: themeColors.white,
      textValue: themeColors.white,
    },
  },
  components: {
    title: themeColors.white,
    icon: themeColors.white,
  },
};
