import styled from "styled-components";

export const StyledButtonActions = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  &:hover{
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }
`