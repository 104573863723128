export const appConfig = {
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  API_URL: process.env.REACT_APP_API_URL,
  APP_TOKEN_NAME: "app_token",
  DEFAULT_LOGIN_DESTINATION: "/home",
  DEFAULT_LOGIN_PAGE: "/login",
  NOT_FOUND_PAGE: "/status/404",
  API_URL_UM: process.env.REACT_APP_API_URL_UM,
  HOME_PAGE: "/",
  RESUME_PAGE: "/resume",
  BOOKING_PAGE: "/home",
  APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
  ROL_ADMIN_NAME: process.env.REACT_APP_ROL_ADMIN_NAME,
  ROL_GUEST_NAME: process.env.REACT_APP_ROL_GUEST_NAME,
  BOOKING_DATA: "alamar_booking_data",
  API_URL_WEATHER: "https://api.openweathermap.org/data/2.5/weather",
  API_WEATHER: process.env.REACT_APP_API_WEATHER,
  CARPA_SKU: process.env.REACT_APP_CARPA_SKU,
  SOMBRILLA_SKU: process.env.REACT_APP_SOMBRILLA_SKU,
  CAMASTRO_SKU: process.env.REACT_APP_CAMASTRO_SKU,
  SEARCH_TABLE_DELAY_REQUEST_MS: 1000,
  CONTACT_WHATSAPP: process.env.REACT_APP_CONTACT_WHATSAPP,
};
