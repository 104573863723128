import {Category, EndpointCategory} from "../domain/Category.model";
import {NOT_CATEGORY_ID} from "../category.constants";

export const createAdapterToEndpointCategory = (category: Category) => {
    const parent = category.parentCategory ? typeof category.parentCategory !== 'string' ? createAdapterToEndpointCategory(category.parentCategory) : category.parentCategory : undefined;
    const formattedCategory: EndpointCategory = {
        id: category.id,
        slug: category.slug,
        name: category.name,
        image: category.image,
        description: category.description,
        parentCategory: parent === NOT_CATEGORY_ID ? undefined : parent,
        order: category.order
    };
    return formattedCategory;
}

export const createAdapterCategory = (category: EndpointCategory) => {
    const formattedCategory: Category = {
        id: category.id,
        slug: category.slug,
        name: category.name,
        image: category.image,
        description: category.description,
        parentCategory: category.parentCategory ? typeof category.parentCategory !== 'string' ? createAdapterCategory(category.parentCategory) : category.parentCategory : undefined,
        order: category.order
    };
    return formattedCategory;
}