import {
  ColumnType, IdentifierFilterOperator, NumberFilterOperator,
  OrdersColumn,
  Table,
  TableActionsProps,
  TableColumn, TableParameters, TextFilterOperator
} from "../../shared-kernel/components/Table";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDeleteMenuMutation, useGetMenusQuery} from "../services/menuApi";
import {Menu} from "../domain/Menu.model";
import {useNavigate} from "react-router-dom";
import {StyledMenuTableIcon} from "../styled-components/StyledMenuTableIcon";
import SuspenseLoader from "../../shared-kernel/components/SuspenseLoader/SuspenseLoader";
import translate from "../../language/translate";
import {setNotification} from "../../shared-kernel/components/NotificationBar/state/notification.reducer";
import {useAppDispatch} from "../../utilities/hooks/redux.hooks";
import FolderIcon from "@mui/icons-material/Folder";

function MenusTable() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const columnsTable: Array<TableColumn<Menu>> = [
    {
      field: "id",
      label: "ID",
      type: ColumnType.TEXT,
      order: false,
      hidden: true
    },
    {
      field: "icon",
      label: intl.formatMessage({
        id: 'icon',
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "10%",
      hidden: false,
      textAlign: 'center',
      render: (row) => row.icon ? <StyledMenuTableIcon src={row.icon} alt={`icon_${row.name}`}  /> : <FolderIcon />
    },
    {
      field: "order",
      label: intl.formatMessage({
        id: 'order',
      }),
      type: ColumnType.NUMBER,
      filterOperators: Object.values(NumberFilterOperator),
      width: "10%",
      order: true,
      hidden: false,
    },
    {
      field: "name",
      label: intl.formatMessage({
        id: 'name',
      }),
      type: ColumnType.TEXT,
      filterOperators: Object.values(TextFilterOperator),
      order: true,
      width: "25%",
      hidden: false,
    },
    {
      field: "label",
      label: intl.formatMessage({
        id: 'label',
      }),
      type: ColumnType.TEXT,
      filterOperators: Object.values(TextFilterOperator),
      order: true,
      width: "25%",
      hidden: false,
    },
    {
      field: "father",
      label: intl.formatMessage({
        id: 'father',
      }),
      type: ColumnType.IDENTIFIER,
      filterOperators: Object.values(IdentifierFilterOperator),
      width: "25%",
      order: false,
      hidden: false,
      render: (row) => <span>{typeof row.parentMenu !== 'string' ? row.parentMenu?.label : row.parentMenu ?? '---' }</span>
    },
  ];

  const [ parameters, setParameters ] = useState<TableParameters<Menu>>({
    search: '',
    searchableColumns: ['name'],
    page: 1,
    pageSize: 20,
    order: { field: "id", order: OrdersColumn.ASC },
  });

  const tableActions: TableActionsProps<Menu>[] = [
    {
      name: intl.formatMessage({ id: 'add'}),
      toExecute: () => {
        navigate('/menus/add')
      },
      freeAction: true
    },
    {
      name: "edit",
      toExecute: (data) => {
        navigate(`/menus/edit/${data?.id}`)
      },
    },
    {
      name: "delete",
      toExecute: (data) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(intl.formatMessage({
          id: 'confirm delete?',
        }))) {
          return;
        }
        if(data?.id !== undefined) {
          deleteMenu(data.id);
        }
      },
      color: () => "#ff0000"
    }
  ];

  const { data, isLoading, isError, error } = useGetMenusQuery(parameters);

  const [deleteMenu] = useDeleteMenuMutation();

  const handleParameters = (newParameters: TableParameters<Menu>) => {
    setParameters(newParameters)
  };

  useEffect(() => {
    if(isError){
      dispatch(
          setNotification({
            show: true,
            severity: "error",
            message: intl.formatMessage({
              // @ts-ignore
              id: error.message ?? error?.data?.msg ?? 'error loading data',
            })
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoading])

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (isError) {
    return <div>{translate('error loading data')}</div>;
  }

  return (
    <Table<Menu>
      tableName='menus_table'
      columns={columnsTable}
      data={data}
      parameters={parameters}
      onChangeParameters={handleParameters}
      actions={tableActions}
    />
  );
}

export default MenusTable;
