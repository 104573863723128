import styled from "styled-components";

export const SidebarNavLogout = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        transition: all 200ms ease-in-out;
        color: ${(props)=>props.theme.layout.sidebar.menuItemIconColorActive};
    }
    span {
        color: ${(props)=>props.theme.layout.sidebar.textColor};
        text-transform: uppercase;
        transition: all 1000ms ease-in-out;
        opacity: 1;
        margin: 0 0 0 10px;
    }
    &:hover {
      background: ${(props)=> `${props.theme.layout.sidebar.menuItemIconColorActive}40`};
    }
`