import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../config";
import {
  Booking,
  BookingDataProps,
  EndpointBooking,
} from "../domain/Booking.model";
import { ResortApplication } from "../../resort/domain/Resort.model";
import { TableOptions } from "../../shared-kernel/components/Table";
import { createAdapterBooking } from "../adapters/booking.adapter";

const API_URL = appConfig.API_URL;

const BOOKINGS_TAG = "Bookings";

export const bookingApi = createApi({
  reducerPath: "BookingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: [BOOKINGS_TAG],
  endpoints: (builder) => ({
    getBookings: builder.query<
      BookingDataProps,
      (TableOptions<Booking> & ResortApplication) | undefined
    >({
      query: (tableOptions) => {
        const applicationId =
          tableOptions?.applicationId ?? appConfig.APPLICATION_ID;
        let queryParams: string = `applicationId=${applicationId}`;
        if (tableOptions !== undefined && tableOptions.page) {
          const page = tableOptions.page - 1;
          queryParams += `&page=${page}`;
        }
        if (tableOptions !== undefined && tableOptions.pageSize) {
          queryParams += `&pageSize=${tableOptions.pageSize}`;
        }
        if (tableOptions !== undefined && tableOptions.order) {
          const parsedOrder = JSON.stringify({
            field: tableOptions.order.field,
            order: tableOptions.order.order,
          });
          queryParams += `&order=${parsedOrder}`;
        }
        if (tableOptions !== undefined && tableOptions.search) {
          queryParams += `&search=${tableOptions.search}`;
        }
        return `bookings/list${queryParams !== "" ? `?${queryParams}` : ""}`;
      },
      providesTags: [BOOKINGS_TAG],
      transformResponse: (response: any) => {
        return {
          data: response.data.map((booking: EndpointBooking) =>
            createAdapterBooking(booking)
          ),
          page: response.page,
          total: response.total,
        };
      },
    }),
    updateBooking: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `bookings/${data.id}`,
          method: "PUT",
          body: {
            ...data,
            applicationId: data.applicationId ?? appConfig.APPLICATION_ID,
          },
        };
      },
      invalidatesTags: [BOOKINGS_TAG],
    }),
    changePayment: builder.mutation<any, any>({
        query: (data: any) => {
          return {
            url: `bookings/changepayment/${data.id}`,
            method: "PUT",
            body: {
              ...data,
              applicationId: data.applicationId ?? appConfig.APPLICATION_ID,
            },
          };
        },
        invalidatesTags: [BOOKINGS_TAG],
      }),
    getBookingById: builder.query<EndpointBooking, string | undefined>({
      query: (id) => `bookings/${id}?applicationId=${appConfig.APPLICATION_ID}`,
      providesTags: (result, error, id) => [{ type: "Bookings", id: id }],
      transformResponse: (response: EndpointBooking) =>
        createAdapterBooking(response),
    }),
    createBooking: builder.mutation<any, any>({
      query: (data: any) => ({
        url: "bookings/",
        method: "POST",
        body: {
          ...data,
          applicationId: data.applicationId ?? appConfig.APPLICATION_ID,
        },
      }),
      invalidatesTags: [BOOKINGS_TAG],
    }),
    deleteBooking: builder.mutation<any, any>({
      query: (id) => ({
        url: `bookings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [BOOKINGS_TAG],
    }),
  }),
});

export const {
  useGetBookingByIdQuery,
  useGetBookingsQuery,
  useCreateBookingMutation,
  useUpdateBookingMutation,
  useDeleteBookingMutation,
  useChangePaymentMutation
} = bookingApi;
