import {
  StyledSidebarNavMenuItemLink,
  StyledSidebarNavMenuItemName
} from "../styled-components/StyledSidebarNavMenuItemLink";
import FolderIcon from "@mui/icons-material/Folder";
import { SidebarNavMenuItemContainer } from "../styled-components/SidebarNavMenuItemContainer";
import { SidebarMenuItemIcon } from "../styled-components/SidebarMenuItemIcon";
import { useContext } from "react";
import { SidebarContext } from "../../../Sidebar/SidebarContext";
import {SidebarMenu} from "../domain/sidebarNav";

function SidebarNavMenuItemNode({
  item,
  showname,
} : {
    item: SidebarMenu;
    showname: boolean;
}) {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <SidebarNavMenuItemContainer show={showname}>
      <StyledSidebarNavMenuItemLink
        to={item.action ?? "#"}
        $showname={showname}
        onClick={closeSidebar}
      >
        {item.icon ? (
          <SidebarMenuItemIcon
            src={item.icon}
            alt={`menu_icon_${item.label}`}
          />
        ) : (
          <FolderIcon />
        )}
        <StyledSidebarNavMenuItemName $showname={showname}>{item.label}</StyledSidebarNavMenuItemName>
      </StyledSidebarNavMenuItemLink>
    </SidebarNavMenuItemContainer>
  );
}

export default SidebarNavMenuItemNode;
