import styled from "styled-components";

export const StyledTopbarLayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-width: 375px;
  background-color: ${props => props.theme.layout.general.bodyBg};
`;
