import styled from "styled-components";

interface StyledSidebarNavContainerProps {
    openNav?: boolean;
    openWidth: string;
    closeWidth: string;
}

export const StyledSidebarNavContainer = styled.aside<StyledSidebarNavContainerProps>`
  position: fixed;
  top: 50px;
  left: 0;
  width: ${(props) => (props.openNav ? props.openWidth : props.closeWidth)};
  height: calc(100% - 50px);
  display: grid;
  grid-template-rows: 50px 1fr;
  background-color: ${(props)=>props.theme.layout.sidebar.background};
  transition: all 150ms ease-in-out;
  box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1), 0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1), 0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
  padding: 20px 0;
  overflow-y: auto;
  z-index: 100;
`;