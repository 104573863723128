import { EndpointRole, Role } from "../domain/Role.model";
import {createAdapterApplication} from "../../application/adapters/application.adapter";
import {createAdapterPermission} from "../../permission/adapters/permission.adapter";

export const createAdapterRole = (role: EndpointRole) => {
    const permissions = role.permissions ? role.permissions.map(permission => createAdapterPermission(permission)) : [];
    const formattedRole: Role = {
        id: role.id,
        name: role.name,
        application: createAdapterApplication(role.application),
        permissions: permissions
    };
    return formattedRole;
}