import styled from "styled-components";

export const StyledBlur = styled.div `
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fafafa10;
    backdrop-filter: blur(3px);
    transition: all 0.5s ease-out;
    pointer-events:none; 
    z-index: 9;
`