import styled from "styled-components";

export const StyledProfileText = styled.div`
  color: ${(props) => props.theme.layout.sidebar.textColor};
  white-space: nowrap;
  text-align: right;
  padding-right: 20px;
  h3 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 0.7rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledProfileName = styled.span`
    text-transform: capitalize;
    user-select: none;
    font-family: "Chau Philomene One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
`

interface StyledProfileRoleProps {
    resort?: any;
}

export const StyledProfileRole = styled.span<StyledProfileRoleProps>`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: 400;
  user-select: none;
  font-family: "Roboto", sans-serif;
`


export const StyledProfileResort = styled.span`
  font-size: 0.7rem;
  font-weight: 600;
`
