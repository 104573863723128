import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../shared-kernel/components/Table";
import { appConfig } from "../../config";
import {
  Amenity,
  AmenityDataProps,
  EndpointAmenity,
} from "../domain/Amenity.model";
import { createAdapterAmenity } from "../adapter/amenity.adapter";

const API_URL = appConfig.API_URL;

const AMENITIES_TAG = "Amenities";

export const amenityApi = createApi({
  reducerPath: "AmenityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: [AMENITIES_TAG],
  endpoints: (builder) => ({
    getAmenities: builder.query<
      AmenityDataProps,
      TableOptions<Amenity> | undefined
    >({
      query: (tableOptions: TableOptions<Amenity>) => {
        let queryParams: string = "";
        if (tableOptions !== undefined && tableOptions.page) {
          const page = tableOptions.page;
          queryParams += `page=${page}`;
        }
        if (tableOptions !== undefined && tableOptions.pageSize) {
          queryParams += `&pageSize=${tableOptions.pageSize}`;
        }
        if (tableOptions !== undefined && tableOptions.order) {
          const parsedOrder = JSON.stringify({
            field: tableOptions.order.field,
            order: tableOptions.order.order,
          });
          queryParams += `&orderBy=${parsedOrder}`;
        }
        if (tableOptions !== undefined && tableOptions.search) {
          queryParams += `&search=${tableOptions.search}`;
        }
        return `amenities${queryParams !== "" ? `?${queryParams}` : ""}`;
      },
      providesTags: [AMENITIES_TAG],
      transformResponse: (
        response: any,
        _meta,
        params: TableOptions<Amenity>
      ) => {
        const page = params?.page ?? 1;
        return {
          data: response.rows.map((p: EndpointAmenity) =>
            createAdapterAmenity(p)
          ),
          page,
          total: response.count,
        };
      },
    }),
    updateAmenity: builder.mutation<any, EndpointAmenity>({
      query: (data: EndpointAmenity) => ({
        url: `amenities/${data.id}`,
        method: "PUT",
        body: createAdapterAmenity(data),
      }),
      invalidatesTags: [AMENITIES_TAG],
    }),
    getAmenitiesById: builder.query<EndpointAmenity, string | undefined>({
      query: (permissionId) => `amenities/${permissionId}`,
      providesTags: (result, error, amenityId) => [
        { type: "Amenities", id: amenityId },
      ],
      transformResponse: (response: EndpointAmenity) =>
        createAdapterAmenity(response),
    }),
    createAmenity: builder.mutation<any, any>({
      query: (data) => ({
        url: "amenities/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [AMENITIES_TAG],
    }),
    deleteAmenity: builder.mutation<any, any>({
      query: (id) => ({
        url: `amenities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [AMENITIES_TAG],
    }),
  }),
});

export const {
  useGetAmenitiesByIdQuery,
  useGetAmenitiesQuery,
  useCreateAmenityMutation,
  useUpdateAmenityMutation,
  useDeleteAmenityMutation,
} = amenityApi;
