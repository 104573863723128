import { EndpointBooking, Booking } from "../domain/Booking.model";

export const createAdapterBooking = (booking: EndpointBooking) => {
    const formattedBooking: Booking = {
        id: booking.id,
        token: booking.token,
        name: booking.name,
        document: booking.document,
        email: booking.email,
        phone: booking.phone,
        userId: booking.userId,
        from: booking.from,
        to: booking.to,
        discount: booking.discount,
        total: booking.total,
        paid: booking.paid,
        paidData: booking.paidData,
        cancelled: booking.cancelled,
        annotations: booking.annotations,
        details: booking.details,
        source: booking.source
    };
    return formattedBooking;
}
