import {
  BreadcrumbsPag,
  BreadcrumbsPagActive,
  BreadcrumbsPagInicio,
  BreadcrumbsPagInicioActive,
  StyledBreadcrumb,
} from "./styled-components/StyledBreadcrumb";
import { useIntl } from 'react-intl';

function Breadcrumb({ breadcrumbList }: any) {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const containsUUID = (str: string) => uuidRegex.test(str);
  const filteredBreadcrumbList = breadcrumbList.filter((bread: any) => !containsUUID(bread.label));
  const intl = useIntl();
  
  return (
    <StyledBreadcrumb>
      {filteredBreadcrumbList.map((bread: any, index: number) =>
       containsUUID(bread.label) ? null :
        index !== filteredBreadcrumbList.length - 1 ? (
          index !== 0 ? (
            <BreadcrumbsPag key={`breadcrumb_${index}`}>
              <a href={bread.link}>{intl.formatMessage({id: bread.label})}</a>
            </BreadcrumbsPag>
          ) : (
            <BreadcrumbsPagInicio key={`breadcrumb_${index}`}>
              <a href={bread.link}>{intl.formatMessage({ id: bread.label })}</a>
            </BreadcrumbsPagInicio>
          )
        ) : index === 0 && index === filteredBreadcrumbList.length - 1 ? (
          <BreadcrumbsPagInicioActive key={`breadcrumb_${index}`}>
            <a href={bread.link}>{intl.formatMessage({ id: bread.label })}</a>
          </BreadcrumbsPagInicioActive>
        ) : (
          <BreadcrumbsPagActive key={`breadcrumb_${index}`}>
            <a href={bread.link}>{intl.formatMessage({ id: bread.label })}</a>
          </BreadcrumbsPagActive>
        )
      )}
    </StyledBreadcrumb>
  );
}

export default Breadcrumb;
