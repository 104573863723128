import {useEffect, useState} from "react";

export interface ColumnSelectable {
  field: string;
  label: string;
}

export default function useTableColumnSelector(tableName: string, initialColumns: ColumnSelectable[]) {
  const LOCAL_STORAGE_KEY = `${tableName}_tableColumns`;
  const storedColumns = localStorage.getItem(LOCAL_STORAGE_KEY);
  const parsedStorageColumns = storedColumns ? JSON.parse(storedColumns) : undefined;
  const checkInvalidColumns = (parsedStorageColumns:ColumnSelectable[] | undefined) => {
    if (parsedStorageColumns){
      return !parsedStorageColumns.some(item2 => !initialColumns.some(item1 => item1.field === item2.field));
    }
    return false
  }
  const filteredColumns = parsedStorageColumns && checkInvalidColumns(parsedStorageColumns) ? parsedStorageColumns : initialColumns;
  const [selectedColumns, setSelectedColumns] = useState<ColumnSelectable[]>(filteredColumns);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectedColumns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  const addColumn = (newColumn: ColumnSelectable) => {
    setSelectedColumns(prevColumns => [...prevColumns, newColumn]);
  };

  const removeColumn = (columnToRemove: ColumnSelectable) => {
    setSelectedColumns(prevColumns => prevColumns.filter(column => column.field !== columnToRemove.field));
  };

  return {
    selectedColumns,
    addColumn,
    removeColumn,
    setSelectedColumns
  };
};