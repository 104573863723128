import SidebarNavMenuItemGroup from "./SidebarNavMenuItemGroup";
import SidebarNavMenuItemNode from "./SidebarNavMenuItemNode";
import { SidebarMenu } from "../domain/sidebarNav";

function SidebarNavMenuItem({ item, showname}: { item: SidebarMenu, showname: boolean }) {
  return item.submenus.length > 0 ? (
    <SidebarNavMenuItemGroup item={item} showname={showname} />
  ) : (
    <SidebarNavMenuItemNode item={item} showname={showname} />
  );
}

export default SidebarNavMenuItem;
