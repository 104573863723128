import {EndpointProduct, Product} from "../domain/Product.model";
import {createAdapterCategory} from "../../category/adapters/category.adapter";
import {createAdapterBrand} from "../../brand/adapters/brand.adapter";

export const createAdapterProduct = (product: EndpointProduct) => {
    const formattedProduct: Product = {
        id: product.id,
        name: product.name,
        sku: product.sku,
        description: product.description,
        category: typeof product.category !== 'string' ? createAdapterCategory(product.category) : product.category,
        brand: typeof product.brand !== 'string' ? createAdapterBrand(product.brand) : product.brand,
        price: product.price,
        images: product.images,
    };
    return formattedProduct;
}