import styled from "styled-components";

export const StyledProfilePicture = styled.div`
  position: relative;
  margin-right: 10px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  cursor: pointer;
    svg {
        object-fit: contain;
        width: 100%;
        height: 100%;
        color: ${props => props.theme.primary.main};
    }
`;
