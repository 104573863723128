import styled from "styled-components";
interface InputLayoutProps {
  disableBorder?: boolean;
  hidden?: boolean;
  width?: string;
}
export const StyledCheckboxLabel = styled.label`
  margin: 0 0 0 5px!important;
  font-size: 1em!important;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const StyledFormLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 100ms ease-in-out;
  cursor: text;
  user-select: none;
  border-radius: 4px 4px 0 0;
`;
export const DefaultInput = styled.input`
  position: relative;
  width: 100%;
  padding: 8px 14px;
  outline: none;
  font-size: 1em;
  color: ${(props) => props.theme.black.main};
  border-radius: 5px;
  border: none;
  &::placeholder {
    color: transparent;
  }
  &:focus
    ~ ${StyledFormLabel},
    &:not(:placeholder-shown)&:not(:focus)
    ~ ${StyledFormLabel} {
    top: 0;
    left: 0;
    transform: translate(2px, -50%);
    background-color:${(props) => props.theme.layout.general.bodyTextColor};
    padding: 2px 5px;
    font-size: 0.75rem;
  }
`;
export const InputLayout = styled.div<InputLayoutProps>`
  position: relative;
  display: ${(props) => (props.hidden ? "none" : "inherit")};
  margin-top: 8px;
  border: ${(props) =>
    props.disableBorder ? "none" : `1px solid ${props.theme.black.light}`};
  border-radius: 6px;
  width: 100%;
  &:hover,
  ${DefaultInput}:focus {
    box-shadow: ${(props) =>
      props.disableBorder ? "none" : `0 0 5px ${props.theme.black.light}`};
  }
  @media (min-width: 1024px) {
    width: ${props => props.width ? props.width : 'auto'};
  }
`;

export const NormalInput = styled(DefaultInput)`
  border: 1px solid black;
  width: auto;
`
export const DefaultCheckboxInput = styled.input`
  position: relative;
  padding: 8px 14px;
  outline: none;
  font-size: 1em;
  color: ${(props) => props.theme.black.main};
  border-radius: 5px;
  border: none;
  &::placeholder {
    color: transparent;
  }
  &:focus
    ~ ${StyledFormLabel},
    &:not(:placeholder-shown)&:not(:focus)
    ~ ${StyledFormLabel} {
    top: 0;
    left: 0;
    transform: translate(2px, -50%);
    background-color:${(props) => props.theme.layout.general.bodyTextColor};
    padding: 2px 5px;
    font-size: 0.75rem;
  }
`;