import { FieldErrors } from "react-hook-form";
import { InputError } from "./styled-components/InputError";
import {
  DefaultCheckboxInput,
  DefaultInput,
  InputLayout,
  StyledCheckboxLabel,
  StyledFormLabel,
} from "./styled-components/InputLayout";
import React, { ReactElement } from "react";
const formValidation = (errors: FieldErrors, errorKey: string) => {
  return (
    <InputError show={!!errors[errorKey]}>
      {errors[errorKey]?.message as string}
    </InputError>
  );
};
interface InputProps {
  id?: string;
  register: any;
  name: string;
  errors?: FieldErrors;
  label?: string;
  type: InputType;
  disabled?: boolean;
  autoComplete?: string;
  innerHtmlInput?: ReactElement;
  hidden?: boolean;
  width?: string;
  readOnly?: boolean;
}
export enum InputType {
  NUMBER = "number",
  PASSWORD = "password",
  SEARCH = "search",
  TEXT = "text",
  HIDDEN = "hidden",
  CHECKBOX = "checkbox",
  DATE = "date"
}
export const Input = ({
  id,
  register,
  name,
  errors,
  label = "",
  type,
  disabled = false,
  autoComplete,
  innerHtmlInput,
  hidden,
  width,
  readOnly,
}: InputProps) => {
  return innerHtmlInput ? (
    <InputLayout
      disableBorder={type === InputType.CHECKBOX}
      hidden={hidden}
      width={width}
    >
    {React.cloneElement(innerHtmlInput, {
      ...register,
      id,
      name,
      errors,
      label,
      type,
      disabled,
      autoComplete,
      readOnly,
      width,
      hidden
    })}
    {errors && formValidation(errors, name)}
    </InputLayout>
  ) : (
    <InputLayout
      disableBorder={type === InputType.CHECKBOX}
      hidden={hidden}
      width={width}
    >
      {
        type === InputType.CHECKBOX ?
        <DefaultCheckboxInput 
          disabled={disabled}
          type={type}
          id={id ?? name}
          {...register}
          placeholder={label}
          variant="outlined"
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
        :
        <DefaultInput
          disabled={disabled}
          type={type}
          id={id ?? name}
          {...register}
          placeholder={label}
          variant="outlined"
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
      }
      {type === InputType.CHECKBOX ? (
        <StyledCheckboxLabel htmlFor={id}>{label}</StyledCheckboxLabel>
      ) : null}
      {
        type !== InputType.CHECKBOX ?
        <StyledFormLabel htmlFor={id ?? name}>{label}</StyledFormLabel>
        : null
      }      
      {errors && formValidation(errors, name)}
    </InputLayout>
  );
};
export default Input;









