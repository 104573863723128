import styled from "styled-components";

export const StyledPrincipalTitle = styled.h1`
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  text-transform: uppercase;
  color: ${props => props.theme.secondary.main};
  padding: 10px;
  font-size: 1.25rem;
  @media (min-width: 1024px) {
    font-size: 1.85rem;
  }
`