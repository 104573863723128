import {EndpointInventory, Inventory} from "../domain/Inventory.model";

export const createAdapterInventory = (inventory: EndpointInventory) => {
    const formattedInventory: Inventory = {
        id: inventory.id,
        name: inventory.name,
        description: inventory.description
    };
    return formattedInventory;
}


export const createAdapterEndpointInventory = (inventory: Inventory) => {
    const formattedEndpointInventory: EndpointInventory = {
        id: inventory.id,
        name: inventory.name,
        description: inventory.description
    };
    return formattedEndpointInventory;
}