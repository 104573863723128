import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface StyledSidebarNavMenuItemLinkProps {
  to: string;
  $showname?: boolean;
}

export const StyledSidebarNavMenuItemLink = styled(
  NavLink
)<StyledSidebarNavMenuItemLinkProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color:${(props) => props.theme.layout.sidebar.textColor};
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  padding: 8px 0 8px 12.5px;
  &.active {
    color: ${(props) => props.theme.layout.sidebar.menuItemIconColorActive};
    font-weight: 600;
  }
`;

interface StyledSidebarNavMenuItemNameProps {
  $showname?: boolean;
}

export const StyledSidebarNavMenuItemName = styled.span<StyledSidebarNavMenuItemNameProps>`
  margin: 0 0 0 10px;
  text-overflow: ellipsis;
  width: calc(100% - 80px);
  overflow: hidden;
  white-space: nowrap;
  font-size: 1rem;
`

interface StyledSidebarNavMenuIteButtonProps {
  expand: boolean;
  $showname?: boolean;
}

export const StyledSidebarNavMenuItemButton = styled.div<StyledSidebarNavMenuIteButtonProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${(props)=>props.theme.layout.sidebar.textColor};
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
  padding: 8px 0 8px 12.5px;
  svg:last-child {
    transition: all 300ms ease-in-out;
    transform: ${(props) => (props.expand ? "rotate(90deg)" : "rotate(0)")};
  }
`;
