import styled from "styled-components";
import { TextField } from "@mui/material";

export const DefaultInput3 = styled(TextField)`
    
`

export const DefaultInput2 = styled.input`
    position: relative;
    width: 100%;
    padding: 15px 20px;
    outline: none;
    font-size: 1.25em;
    color: ${props => props.theme.layout.login.inputColor};
    border-radius: 5px;
    background: ${props => props.theme.layout.login.inputBg};
    border: none;
    &::placeholder {
        color: ${props => props.theme.layout.login.title};
    }
`
export const DefaultInput = styled.input`
  position: relative;
  margin-bottom: 5px;
  outline: none;
  width: 100%;
  padding: 8.5px 14px;
  font-size: 0.75em;
  color: ${(props) => props.theme.table.textColor};
  background-color: ${(props) => props.theme.base.main};
  border-radius: 5px;
  border: none;
`;